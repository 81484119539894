//@flow
import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { palette } from '@dt/theme';

type Props = {|
  +style?: mixed,
  +m?: number,
  +p?: number,
  +variant: 'info' | 'error' | 'success',
  +message: string,
|};

const MessageComponent = function Message({
  style,
  m,
  p,
  variant,
  message,
}: Props) {
  const padding = typeof p !== 'undefined' ? p : 2;
  const margin = typeof m !== 'undefined' ? m : 2;

  switch (variant) {
    case 'error':
      return (
        <Box
          bgcolor={palette.red50}
          color={palette.red10}
          p={padding}
          m={margin}
          borderRadius={2}
          whiteSpace="pre-line" // Used to allow messages to be multiline strings.
          style={style}
        >
          <Typography variant="body1">{message}</Typography>
        </Box>
      );

    case 'success':
      return (
        <Box
          bgcolor={palette.green50}
          color={palette.green10}
          p={padding}
          m={margin}
          borderRadius={2}
          whiteSpace="pre-line" // Used to allow messages to be multiline strings.
          style={style}
        >
          <Typography variant="body1">{message}</Typography>
        </Box>
      );

    case 'info':
    default:
      return (
        <Box
          bgcolor={palette.gray50}
          color={palette.gray10}
          p={padding}
          m={margin}
          borderRadius={2}
          whiteSpace="pre-line" // Used to allow messages to be multiline strings.
          style={style}
        >
          <Typography variant="body1">{message}</Typography>
        </Box>
      );
  }
};

export const Message = memo<Props>(MessageComponent);
