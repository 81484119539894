//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation ApigeeAuthenticatorsCreateMutation(
    $body: ApigeeAuthenticatorsCreateParams!
  ) {
    apigee_authenticators_create(body: $body) {
      id
      date_created
      added_by_user_id
      organization
    }
  }
`;

export default {
  create,
};
