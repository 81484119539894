//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation KongAuthenticatorsCreateMutation(
    $body: KongAuthenticatorsCreateParams!
  ) {
    kong_authenticators_create(body: $body) {
      id
      date_created
      added_by_user_id
      kong_admin_subdomain
    }
  }
`;

export default {
  create,
};
