//@flow
import React, { memo, useMemo, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  InputBase,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import debounce from 'lodash/fp/debounce';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  CardContent: {
    padding: 8,
    '&:last-child': {
      paddingBottom: 8,
    },
  },
});

type Props = {
  value: ?string,
  onChange: string => void,
};

export default memo<Props>(function KeyWordSearchInput({
  value,
  onChange,
}: Props) {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState<?string>(value);

  const handleChange = useMemo(
    () => debounce(500)((value: string) => onChange(value)),
    [onChange],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent classes={{ root: classes.CardContent }}>
            <InputBase
              value={inputValue || ''}
              onChange={e => {
                setInputValue(e.target.value);
                handleChange(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              fullWidth={true}
              placeholder="Keyword Search"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
