//@flow
import React from 'react';
import { OpenApiDefinitionsList } from './../openapi_definitions/OpenApiDefinitionsList';

type Props = {|
  +onComplete: () => void,
|};

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add openapi definitions.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperOpenApiDefinitionsComponent = function ConfigurationsCloudStepperOpenApiDefinitions({
  onComplete,
}: Props) {
  return <OpenApiDefinitionsList onOpenApiDefinitionUploadClose={onComplete} />;
};

export const ConfigurationsCloudStepperOpenApiDefinitions = ConfigurationsCloudStepperOpenApiDefinitionsComponent;
