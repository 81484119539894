// @flow

const JSONBlob = `
\`\`\` 
{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Resource": "*",
      "Action": [
        "apigateway:GET"
      ]
    }
  ]
}
\`\`\``;

export default `
1. Sign in to the AWS Management Console [by clicking here](https://console.aws.amazon.com/iam/home#/policies$new?step=edit)
   - The link will take you to create policy page
2. Select the **JSON** tab in the policy editor and paste the following policy (overwriting the existing items):
   ${JSONBlob}
3. Select **Review policy**, and enter the name below:
   - \`DataTheorem-APIGateway-SecurityAudit\`
4. Select **Create policy**. Close the tab and navigate back to our portal and select **Next**

Should you have any issues, contact support@datatheorem.com
`;
