// @flow
import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import { ConfigurationsCloudAddAccessPicker } from '../configurations/ConfigurationsCloudAddAccessPicker';
import type { ConfigurationsCloudVariant } from '../types';
import { DashboardHostPieChartTooltip } from './DashboardHostPieChartTooltip';
import { DashboardHostPieChartAddAccess } from './DashboardHostPieChartAddAccess';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: palette.white,
    // This should use the theme but we're migrating between two versions of mui.
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    border: `1px solid ${palette.gray30}`,
    padding: 10,
  },
}));

type Props = {|
  +variant: ConfigurationsCloudVariant,
  +enabled: boolean,
|};

const DashboardHostPieChartAddAccessButtonComponent = function DashboardHostPieChartAddAccessButton({
  variant,
  enabled,
}: Props) {
  const classes = useStyles();

  return (
    <>
      {!enabled && (
        <div
          style={{
            position: 'absolute',
            bottom: 5,
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 3,
          }}
        >
          <Tooltip
            classes={classes}
            placement="bottom"
            title={
              <DashboardHostPieChartTooltip>
                {
                  <>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                      Unconfigured source discovered
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                      via DataTheorem network analyzer!
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                      Click to configure!
                    </Typography>
                  </>
                }
              </DashboardHostPieChartTooltip>
            }
          >
            {/* Span required to expand content for tooltip. */}
            <span>
              <ConfigurationsCloudAddAccessPicker variant={variant}>
                <DashboardHostPieChartAddAccess />
              </ConfigurationsCloudAddAccessPicker>
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export const DashboardHostPieChartAddAccessButton = DashboardHostPieChartAddAccessButtonComponent;
