//@flow
import React, { useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import { useQuery } from '@dt/apollo-link-schema-rest';
import cloud_authenticators from '@dt/graphql-support/horizon/cloud_authenticators';
import { Message } from '../Message';
import { ConfigurationsCloudAddAccessPicker } from './ConfigurationsCloudAddAccessPicker';
import { ConfigurationsCloudAvatar } from './ConfigurationsCloudAvatar';
import { ConfigurationsCloudNameIdentifier } from './ConfigurationsCloudNameIdentifier';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';
import type { ConfigurationsCloudVariant } from '../types';
import values from 'lodash/fp/values';
import Text from '../Text';

import type { CloudAuthenticatorsListQuery } from '@dt/graphql-support/types';

const useStyles = makeStyles({
  materialIconStyle: { color: palette.gray20 },
  table: {
    backgroundColor: palette.white,
  },
  tableCellStyle: {
    paddingRight: '16px !important',
  },
  descriptorCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  onlineIndicator: {
    textTransform: 'uppercase',
    color: palette.green20,
    backgroundColor: palette.green50,
    borderRadius: 2,
    padding: '2px 4px',
  },
  offlineIndicator: {
    textTransform: 'uppercase',
    color: palette.red20,
    backgroundColor: palette.red50,
    borderRadius: 2,
    padding: '2px 4px',
  },
});

type Variant = 'all' | 'asm' | 'api-gateways';

const AllVariants = [
  ...values(CloudAuthenticatorTypeValues),
  'ip_ranges',
  'network_services',
  'openapi_definitions',
];
const GatewayVariants = [
  CloudAuthenticatorTypeValues.Apigee,
  CloudAuthenticatorTypeValues.Axway,
  CloudAuthenticatorTypeValues.Kong,
  CloudAuthenticatorTypeValues.Mulesoft,
];
const AsmVariants = Object.values(CloudAuthenticatorTypeValues);

type Props = {|
  +variant?: Variant,
  +excludeConfiguredVariants?: boolean,
  +excludeHeader?: boolean,
  +includeDateAddedHeader?: boolean,
  +includeDiscoveryStatusHeader?: boolean,
|};

/*
 * Table to show the user which configurations they can add access for and allow them to add.
 *
 * @param variant - Which configuration variants to render, if not provided shows all of them
 * @param excludeConfiguredVariants - When enabled only show variants that have not been configured
 * @param includeDateAddedHeader - Whether or not to show the date added header
 * @param includeDiscoveryStatusHeader - Whether or not to show the status header
 */
const ConfigurationsCloudDataSourcesTableAddAccessComponent = function ConfigurationsCloudDataSourcesTableAddAccess({
  variant,
  excludeHeader,
  excludeConfiguredVariants,
  includeDateAddedHeader,
  includeDiscoveryStatusHeader,
}: Props) {
  const classes = useStyles();

  const { data, loading, error, fetchMore } = useQuery<
    CloudAuthenticatorsListQuery,
    _,
  >(cloud_authenticators.list);

  useEffect(() => {
    fetchMore && fetchMore();
  }, [fetchMore]);

  const all_authenticators = !data
    ? []
    : data.cloud_authenticators_list.cloud_authenticators.filter(Boolean);

  const sources: $ReadOnlyArray<ConfigurationsCloudVariant> = AllVariants.filter(
    type =>
      excludeConfiguredVariants
        ? !all_authenticators.some(a => a.authenticator_type === type)
        : true,
  ).filter(
    type =>
      !variant ||
      variant === 'all' ||
      (variant === 'asm' && AsmVariants.includes(type)) ||
      (variant === 'api-gateways' && GatewayVariants.includes(type)),
  );

  if (!sources || sources.length <= 0) {
    return null;
  }

  return (
    <Table className={classes.table}>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>

      {!excludeHeader && (
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellStyle}>
              <Text style={{ margin: 0 }} variant="titleXS">
                Unconfigured Account
              </Text>
            </TableCell>

            <TableCell className={classes.tableCellStyle}>
              <Text style={{ margin: 0 }} variant="titleXS">
                Account Nickname
              </Text>
            </TableCell>

            <TableCell className={classes.tableCellStyle}>
              {includeDateAddedHeader && (
                <Text style={{ margin: 0 }} variant="titleXS">
                  Date Added
                </Text>
              )}
            </TableCell>

            <TableCell className={classes.tableCellStyle}>
              {includeDiscoveryStatusHeader && (
                <Text style={{ margin: 0 }} variant="titleXS">
                  Status
                </Text>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      )}

      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Skeleton
                animation="pulse"
                variant="text"
                height={40}
                width="100%"
              />
              <Skeleton
                animation="pulse"
                variant="text"
                height={40}
                width="100%"
              />
            </TableCell>
          </TableRow>
        ) : error ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Message m={0} variant="error" message={error.message} />
            </TableCell>
          </TableRow>
        ) : (
          // Should always provide data/sources by this point.
          sources.map(variant => (
            <TableRow key={variant}>
              <TableCell className={classes.tableCellStyle}>
                <div className={classes.descriptorCellContent}>
                  <ConfigurationsCloudAvatar
                    variant={variant}
                    style={{ marginRight: 12 }}
                  />
                  <Text variant="body">
                    <ConfigurationsCloudNameIdentifier
                      variant={variant}
                      fullName
                    />
                  </Text>
                </div>
              </TableCell>

              <TableCell className={classes.tableCellStyle}>
                <Text style={{ margin: 0 }} variant="body">
                  -
                </Text>
              </TableCell>

              <TableCell className={classes.tableCellStyle}>
                <div style={{ cursor: 'pointer' }}>
                  <ConfigurationsCloudAddAccessPicker variant={variant}>
                    <Button
                      variant="contained"
                      aria-label={`onboard-${variant}`}
                      fullWidth
                    >
                      Onboard{' '}
                      <ConfigurationsCloudNameIdentifier variant={variant} />
                    </Button>
                  </ConfigurationsCloudAddAccessPicker>
                </div>
              </TableCell>

              <TableCell className={classes.tableCellStyle}>
                <span className={classes.offlineIndicator}>Offline</span>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export const ConfigurationsCloudDataSourcesTableAddAccess = ConfigurationsCloudDataSourcesTableAddAccessComponent;
