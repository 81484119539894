//@flow
import gql from 'graphql-tag';

const list = gql`
  query IpRangesListQuery($cursor: String) {
    ip_ranges_list(cursor: $cursor) {
      pagination_information {
        next_cursor
      }
      ip_ranges {
        id
        has_continuous_discovery_enabled
        ip_range
      }
    }
  }
`;

const create = gql`
  mutation IpRangesCreateMutation($body: IpRangeCreateParams!) {
    ip_ranges_create(body: $body) {
      ip_ranges {
        id
        has_continuous_discovery_enabled
        ip_range
      }
    }
  }
`;

const patch = gql`
  mutation IpRangesPatchMutation($id: ID!, $body: IpRangePatchParams!) {
    ip_ranges_patch(id: $id, body: $body) {
      ip_ranges {
        id
        has_continuous_discovery_enabled
        ip_range
      }
    }
  }
`;

export default {
  create,
  list,
  patch,
};
