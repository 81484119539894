//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation IpRangeScansCreateMutation($body: IpRangeScanCreateParams!) {
    ip_range_scans_create(body: $body) {
      ip_range_scans {
        ip_address_scan_results {
          ip_address
          online_network_service_urls
        }
        # Different than ip_range_id, this holds the ip address range sent to the backend to be scanned.
        ip_range
        # Potential existing ip address range.
        ip_range_id
        # See 'restSchema.graphql'. Shouldn't need this after backend updates.
        title
      }
      ip_ranges {
        id
        has_continuous_discovery_enabled
        ip_range
      }
    }
  }
`;

export default {
  create,
};
