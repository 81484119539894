// @flow

import ImageCreateAdApp from './ConfigurationsCloudStepperAzureMarkdownStep1ImageCreateAdApp.png';

export default `
In order to set up Azure access you'll first need to create a new Azure AD application.
To get started open a new tab and:

1. Login to your account in the Azure Portal
2. Navigate to the **All Services** page
3. Select **Azure Active Directory** in the Security category
4. Select **App Registrations** from the left menu
5. Click **New Registration** and enter the following values into the form:
  - **Name**: DataTheorem
  - **Account type**: "Accounts in this organizational directory only"
  - **Redirect URI**: https://securetheorem.com/

   You should have something like the following.

![Azure App Registration](${ImageCreateAdApp})

6. Click **Create**
`;
