//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation AwsAuthenticatorsCreateMutation(
    $body: AwsAuthenticatorsCreateParams!
  ) {
    aws_authenticators_create(body: $body) {
      id
      date_created
      added_by_user_id
      role_arn
      external_id
    }
  }
`;

export default {
  create,
};
