// @flow

import ImageAssignReaderRoleToApp from './ConfigurationsCloudStepperAzureMarkdownStep2ImageAssignReaderRoleToApp.png';

export default `
Now that we have a new Azure AD application we need to assign read-only access:

1. Navigate to the **All Services** page
2. Select **Subscriptions** in the General category
3. Select the subscription that you want to give Data Theorem access to
    - If you have multiple subscriptions, you will need to repeat this process for each subscription that you would like Data Theorem to have access to.
4. Select **Access Control (IAM)**
5. Select **Add**, then from the dropdown select **Add Role Assignment**
6. Enter the following values into the form:
    - **Role**: Reader
    - **Assign Access to**: Azure AD user, group, or service principal
    - **Select**: "DataTheorem" app registration (created in the previous step)

   You should have something like the following.

![Azure Assign Reader Role](${ImageAssignReaderRoleToApp})

7. Click **Save**
`;
