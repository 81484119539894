//@flow
import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import cloud_authenticators from '@dt/graphql-support/horizon/cloud_authenticators';
import { ConfigurationsCloudAvatar } from './ConfigurationsCloudAvatar';

import type {
  CloudAuthenticatorsListQuery,
  CloudAuthenticator,
} from '@dt/graphql-support/types';
import Text from '../Text';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useMutation } from '@apollo/client';
import type {
  UpdateCloudAuthenticator,
  UpdateCloudAuthenticatorVariables,
} from '@dt/graphql-support/types/UpdateCloudAuthenticator';

const useStyles = makeStyles({
  tableCellStyle: {
    paddingRight: '16px !important',
  },
  table: {
    backgroundColor: palette.white,
  },
  descriptorCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  onlineIndicator: {
    textTransform: 'uppercase',
    color: palette.green20,
    backgroundColor: palette.green50,
    borderRadius: 2,
    padding: '2px 4px',
  },
  offlineIndicator: {
    textTransform: 'uppercase',
    color: palette.red20,
    backgroundColor: palette.red50,
    borderRadius: 2,
    padding: '2px 4px',
  },
});

type Props = {|
  +authenticator: CloudAuthenticator,
|};

/*
 * Returns back a cloud authenticator table row with editable nickname
 *
 * @param authenticator - the cloud_authenticator object to be displayed in the row
 */
const ConfigurationsCloudDataSourcesTableRowComponent = function ConfigurationsCloudDataSourcesTableRow({
  authenticator,
}: Props) {
  const classes = useStyles();

  const [nickname, setNickname] = useState<string>('');
  const [isNicknameBeingEdited, setIsNicknameBeingEdited] = useState<boolean>(
    false,
  );

  const handleChangeNickame = event => {
    setNickname(event.target.value);
  };

  const handleSaveNickameChange = () => {
    updateAuthenticator({
      variables: {
        id: authenticator.id,
        body: {
          customer_supplied_name: nickname,
        },
      },
    });
  };

  const [
    updateAuthenticator,
    { loading: authenticatorUpdateLoading },
  ] = useMutation<UpdateCloudAuthenticator, UpdateCloudAuthenticatorVariables>(
    cloud_authenticators.update,
    {
      update: (cache, newData) => {
        let q = cache.readQuery<CloudAuthenticatorsListQuery, _>({
          query: cloud_authenticators.list,
        });
        let existingAuthenticators =
          q?.cloud_authenticators_list?.cloud_authenticators || [];
        if (newData.data && newData.data.cloud_authenticator_update) {
          existingAuthenticators = [
            ...existingAuthenticators.filter(
              auth => auth.id !== authenticator.id,
            ),
            newData.data && newData.data.cloud_authenticator_update,
          ];
        }
        cache.writeQuery({
          query: cloud_authenticators.list,
          data: {
            cloud_authenticators_list: {
              ...q?.cloud_authenticators_list,
              cloud_authenticators: existingAuthenticators,
            },
          },
        });
        setIsNicknameBeingEdited(false);
      },
    },
  );

  return (
    <TableRow key={authenticator.id}>
      <TableCell className={classes.tableCellStyle}>
        <div className={classes.descriptorCellContent}>
          <ConfigurationsCloudAvatar
            variant={authenticator.authenticator_type}
            style={{ marginRight: 12 }}
          />
          <Text style={{ margin: 0 }} variant="body">
            {authenticator.cloud_account_id}
          </Text>
        </div>
      </TableCell>

      <TableCell className={classes.tableCellStyle}>
        {isNicknameBeingEdited ? (
          <TextField
            aria-label="edit account nickname textbox"
            variant="outlined"
            size="small"
            autoFocus
            fullWidth
            onChange={handleChangeNickame}
            type="search"
            value={nickname}
          />
        ) : (
          <Text style={{ margin: 0 }} variant="body">
            {authenticator.customer_supplied_name
              ? authenticator.customer_supplied_name
              : '-'}
          </Text>
        )}
      </TableCell>

      <TableCell>
        {isNicknameBeingEdited ? (
          authenticatorUpdateLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <IconButton
                size="small"
                aria-label="save nickname button"
                disabled={nickname === authenticator.customer_supplied_name}
                onClick={() => {
                  setIsNicknameBeingEdited(true);
                  handleSaveNickameChange();
                }}
              >
                <CheckIcon
                  style={{
                    color:
                      nickname === authenticator.customer_supplied_name
                        ? palette.gray30
                        : palette.green30,
                  }}
                />
              </IconButton>

              <IconButton
                size="small"
                aria-label="cancel nickname edit button"
                onClick={() => {
                  setIsNicknameBeingEdited(false);
                }}
              >
                <ClearIcon style={{ color: palette.red30 }} />
              </IconButton>
            </>
          )
        ) : (
          <IconButton
            size="small"
            aria-label="edit nickname button"
            disabled={isNicknameBeingEdited}
            onClick={() => {
              setIsNicknameBeingEdited(true);
              setNickname(
                authenticator.customer_supplied_name
                  ? authenticator.customer_supplied_name
                  : '',
              );
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell className={classes.tableCellStyle}>
        <Text style={{ margin: 0 }} variant="body">
          {new Date(authenticator.date_created).toLocaleDateString()}
        </Text>
      </TableCell>

      <TableCell className={classes.tableCellStyle}>
        <span className={classes.onlineIndicator}>Online</span>
      </TableCell>
    </TableRow>
  );
};

export const ConfigurationsCloudDataSourcesTableRow = ConfigurationsCloudDataSourcesTableRowComponent;
