// @flow
import '@dt/global';
import runApp from './runApp';

runApp();

// $FlowFixMe -- It's a webpack-specific thing
if (module.hot) {
  module.hot.addStatusHandler(status => {
    if (status === 'check') location.reload();
  });
}
