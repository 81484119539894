//@flow
import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export const IpRangesHeader = () => (
  <Box mb={2}>
    <Typography variant="body2" gutterBottom>
      Enter an IP Address Range in CIDR notation below to scan for domains
      within that range.
    </Typography>
    <Typography variant="body2" gutterBottom>
      The provided IP Address Range supplies the network and subnet that will be
      used in the discovery process. Once a valid IP Address is discovered we
      use a reverse DNS lookup to auto-discover domains. An example IP Address
      range of 192.168.86.0/24 will allow Data Theorem to discover any IP
      addresses between 192.168.86.1 and 192.168.86.255
    </Typography>
  </Box>
);
