// @flow

export default `
Now that you've created a new GCP project, the next step is to create a *service account*. 
> Service accounts are GCP's method of providing GCP API access to automated tools. For more information, please see [Understanding Service Accounts](https://cloud.google.com/iam/docs/understanding-service-accounts)

To create the service account:

1.  Follow [this link](https://console.cloud.google.com/iam-admin/serviceaccounts/create) to create a new service account.
    *   You can choose any name and description that you like, but we recommend naming the account *DataTheoremDiscover* with the description "*This service account will be used by Data Theorem to perform resource discovery*".
2.  Click **Continue** on the Service Account Permissions page (you'll be adding permissions later).
    * Then, click **Create Key** near the bottom of the page.
3.  On the right sidebar, ensure that “**JSON**” is selected and then click **CREATE**.
    *   Be sure to save this JSON file, as we will need it in an upcoming step of this process.
4.  Once you've saved the JSON file, click **Done** near the bottom of the page and then copy the email address for the service account you just created from the list of your new project's service accounts
    *   It will look like: *DataTheoremDiscovery@rosy-canyon-234300.iam.gserviceaccount.com*  where "**DataTheoremDiscovery**" is the service account and “**rosy-canyon-234300**” is the project in which the service account was created.
`;
