//@flow
import React, { useState } from 'react';
import {
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AlertIcon from '@material-ui/icons/Warning';
import Skeleton from '@material-ui/lab/Skeleton';

import type { IpRangesListQuery_ip_ranges_list_ip_ranges } from '@dt/graphql-support/types/IpRangesListQuery';

import IpRangesContinuousDiscoveryModal, {
  IpRangesContinuousDiscoveryModalButtons,
} from './IpRangesContinuousDiscoveryModal';

type Props = {|
  +isLoading: boolean,
  +ip_ranges: Array<IpRangesListQuery_ip_ranges_list_ip_ranges>,
  +onChangeContinuousDiscoveryEnabled: (
    ip_range: IpRangesListQuery_ip_ranges_list_ip_ranges,
  ) => void,
|};

/*
 * Show the user's ip ranges in table format.
 *
 * @param isLoading - Whether or not to show loading skeletons or ip ranges
 * @param ip_ranges - Ip ranges provided by the backend
 * @param onChangeContinuousDiscoveryEnabled - Triggered event when the user wants to
 *                                             toggle an ip range's continuous discovery option
 *
 * @example
 *     <IpRangesTable
 *       isLoading={isIpRangesListLoading}
 *       ip_ranges{ip_ranges}
 *       onChangeContinuousDiscoveryEnabled={handleOnContinuousDiscoveryEnabled} />
 */
const IpRangesTableComponent = function IpRangesTable({
  isLoading,
  ip_ranges,
  onChangeContinuousDiscoveryEnabled,
}: Props) {
  const [
    continuousDiscoveryConfirmationIpRange,
    setContinuousDiscoveryConfirmationIpRange,
  ] = useState<?IpRangesListQuery_ip_ranges_list_ip_ranges>(null);
  const [
    showContinuousDiscoveryModal,
    setShowContinuousDiscoveryModal,
  ] = useState<boolean>(false);

  // Set continuous discovery option from modal result.
  const handleContinuousDiscoveryOnModalClosed = ({
    button,
  }: {|
    +button: $Keys<typeof IpRangesContinuousDiscoveryModalButtons>,
  |}) => {
    if (button === IpRangesContinuousDiscoveryModalButtons.ENABLE) {
      // Fire continuous discovery enabled change event.
      if (!continuousDiscoveryConfirmationIpRange) {
        throw new Error(
          'Invalid state. Continuous discovery confirmation ip range not set before showing confirmation.',
        );
      }
      onChangeContinuousDiscoveryEnabled(
        continuousDiscoveryConfirmationIpRange,
      );
    }

    // Close dialog.
    setShowContinuousDiscoveryModal(false);
  };

  // Set continuous discovery option from modal result.
  const handleContinuousDiscoveryCheckboxOnChange = (
    ip_range: IpRangesListQuery_ip_ranges_list_ip_ranges,
  ) => {
    if (!ip_range.has_continuous_discovery_enabled) {
      setContinuousDiscoveryConfirmationIpRange(ip_range);
      setShowContinuousDiscoveryModal(true);
    } else {
      onChangeContinuousDiscoveryEnabled(ip_range);
    }
  };

  return (
    <>
      <Table>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>

        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography variant="subtitle1">IP Address Range</Typography>
            </TableCell>

            <TableCell align="right">
              <Typography variant="subtitle1">Continuous Discovery</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!isLoading && ip_ranges.length <= 0 && (
            <TableRow>
              <TableCell>
                <AlertIcon />
              </TableCell>

              <TableCell>
                <Typography variant="body1">
                  No IP Address Ranges found.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {ip_ranges.map(ip_range => (
            <TableRow key={ip_range.id}>
              <TableCell>{ip_range.ip_range}</TableCell>

              <TableCell align="right">
                <Box flex="1 1 auto" mr={8}>
                  <Checkbox
                    data-testid={`${ip_range.ip_range}_continuous_discovery`}
                    checked={ip_range.has_continuous_discovery_enabled}
                    onChange={() =>
                      handleContinuousDiscoveryCheckboxOnChange(ip_range)
                    }
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton
                  animation="pulse"
                  variant="text"
                  height={40}
                  width="100%"
                />
                <Skeleton
                  animation="pulse"
                  variant="text"
                  height={40}
                  width="100%"
                />
                <Skeleton
                  animation="pulse"
                  variant="text"
                  height={40}
                  width="100%"
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* Modal */}
      {continuousDiscoveryConfirmationIpRange && (
        <IpRangesContinuousDiscoveryModal
          ip_range={continuousDiscoveryConfirmationIpRange.ip_range}
          open={showContinuousDiscoveryModal}
          onModalClosed={handleContinuousDiscoveryOnModalClosed}
        />
      )}
    </>
  );
};

export const IpRangesTable = IpRangesTableComponent;
