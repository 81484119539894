//@flow
import React, { useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import { useQuery } from '@dt/apollo-link-schema-rest';
import cloud_authenticators from '@dt/graphql-support/horizon/cloud_authenticators';
import { Message } from '../Message';
import type { CloudAuthenticatorsListQuery } from '@dt/graphql-support/types';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';
import Text from '../Text';
import { ConfigurationsCloudDataSourcesTableRow } from './ConfigurationsCloudDataSourcesTableRow';

const useStyles = makeStyles({
  tableCellStyle: {
    paddingRight: '16px !important',
  },
  table: {
    backgroundColor: palette.white,
  },
  descriptorCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
  onlineIndicator: {
    textTransform: 'uppercase',
    color: palette.green20,
    backgroundColor: palette.green50,
    borderRadius: 2,
    padding: '2px 4px',
  },
  offlineIndicator: {
    textTransform: 'uppercase',
    color: palette.red20,
    backgroundColor: palette.red50,
    borderRadius: 2,
    padding: '2px 4px',
  },
});

const GatewayVariants = [
  CloudAuthenticatorTypeValues.Apigee,
  CloudAuthenticatorTypeValues.ApigeeOnPrem,
  CloudAuthenticatorTypeValues.Axway,
  CloudAuthenticatorTypeValues.Kong,
  CloudAuthenticatorTypeValues.Mulesoft,
];

type Props = {|
  +includeDateAddedHeader?: boolean,
  +includeDiscoveryStatusHeader?: boolean,
|};

/*
 * Shows the user a table of api gateway configurations.
 *
 * @param includeDateAddedHeader - Whether or not to include the date added header
 * @param includeDiscoveryStatusHeader - Whether or not to include the discovery status header
 */
const ConfigurationsCloudDataSourcesTableApiGatewaysComponent = function ConfigurationsCloudDataSourcesTableApiGateways({
  includeDateAddedHeader,
  includeDiscoveryStatusHeader,
}: Props) {
  const classes = useStyles();

  const { data, loading, error, fetchMore } = useQuery<
    CloudAuthenticatorsListQuery,
    _,
  >(cloud_authenticators.list);

  useEffect(() => {
    fetchMore && fetchMore();
  }, [fetchMore]);

  const sources = !data
    ? null
    : data.cloud_authenticators_list.cloud_authenticators
        .filter(Boolean)
        .filter(auth => GatewayVariants.includes(auth.authenticator_type))
        .slice()
        .sort((a, b) => {
          if (a.id && b.id) {
            if (a.id > b.id) {
              return -1;
            }
            return 1;
          }
          return 0;
        });

  return (
    <Table className={classes.table}>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>

      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellStyle}>
            <Text style={{ margin: 0 }} variant="titleXS">
              Gateway Account
            </Text>
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            <Text style={{ margin: 0 }} variant="titleXS">
              Account Nickname
            </Text>
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            {/* /Edit icon column */}
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            {includeDateAddedHeader && (
              <Text style={{ margin: 0 }} variant="titleXS">
                Date Added
              </Text>
            )}
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            {includeDiscoveryStatusHeader && (
              <Text style={{ margin: 0 }} variant="titleXS">
                Status
              </Text>
            )}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Skeleton
                animation="pulse"
                variant="text"
                height={40}
                width="100%"
              />
              <Skeleton
                animation="pulse"
                variant="text"
                height={40}
                width="100%"
              />
            </TableCell>
          </TableRow>
        ) : error ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Message m={0} variant="error" message={error.message} />
            </TableCell>
          </TableRow>
        ) : (
          // Should always provide data/sources by this point.
          sources?.map(auth => (
            <ConfigurationsCloudDataSourcesTableRow
              key={auth.id}
              authenticator={auth}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
};

export const ConfigurationsCloudDataSourcesTableApiGateways = ConfigurationsCloudDataSourcesTableApiGatewaysComponent;
