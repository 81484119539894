//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation AxwayAuthenticatorsCreateMutation(
    $body: AxwayAuthenticatorsCreateParams!
  ) {
    axway_authenticators_create(body: $body) {
      id
      date_created
      added_by_user_id
      client_id
      organization_id
      added_by_external_user_email
    }
  }
`;

export default {
  create,
};
