//@flow
import gql from 'graphql-tag';

const list = gql`
  query OpenApiDefinitionsListQuery($cursor: String) {
    openapi_definitions_list(cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      openapi_definitions {
        id
        title
        discovered_via
        discovered_via_aws_authenticator_id
        discovered_via_user_id
        original_document
        date_created
      }
    }
  }
`;

const create = gql`
  mutation OpenApiDefinitionsCreateMutation($body: String!) {
    openapi_definitions_create(body: $body) {
      restful_apis {
        title
      }
    }
  }
`;

export default {
  create,
  list,
};
