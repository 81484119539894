// @flow

import Onboarding1 from './ConfigurationsCloudStepperApigeeMarkdownStep1ImageOnboarding1.png';
import Onboarding2 from './ConfigurationsCloudStepperApigeeMarkdownStep1ImageOnboarding2.png';

export default `
1. [Sign in to the Apigee Console by clicking here.](https://login.apigee.com/login)
2. In the top left, click on your name and select the Organization that corresponds to your organization's environment.
3. In the left menu, select **"Admin"** and then
  **"Roles"**.
4. In the "Custom Roles" section, click on **"+ Custom Role"**
5. In the "New Custom Role" page:
    * Enter "DataTheoremAnalyzer" as the Role Name
    * In the "API Proxies" section, check "View”
    * When finished, it should look like this:

    ![Completed Apigee UI](${Onboarding1})

6. Select **Save**

7. In the left menu, select **"Admin"** and then **"Users"**.
8. In the top right, click on **"+ User"**.
9. In the New User page:
    * Enter *cloud@datatheorem.com* as the Email.
    * Select "DataTheoremAnalyzer" as the Role.


    ![Completed Apigee UI](${Onboarding2})
10. Select **Save**
`;
