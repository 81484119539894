// @flow

export default `
In order to set up GCP access you'll first need to create a new project in GCP. 
> Creating a dedicated project instance for your Data Theorem connection facilitiates troubleshooting and permission management. For more information on best practices, see [Using IAM Securely](https://cloud.google.com/iam/docs/using-iam-securely)

To create the new project:

1.  Follow [this link](https://console.cloud.google.com/projectcreate) to the Google Cloud Platform project creation page and create a new project, making sure that your organization is selected in the **Location** field.
2.  With your newly created project selected in the top bar, follow each of the links below to enable the required APIs by clicking the **Enable API** button near the top of each page.
    *   [Cloud Resource Manager API](https://console.cloud.google.com/apis/library/cloudresourcemanager.googleapis.com)  - allows Data Theorem to view resources such as projects
    *   [Firebase Management API](
                https://console.cloud.google.com/apis/library/firebase.googleapis.com)  - allows Data Theorem to view Firebase projects and associated resources
    *   [Firebase Rules API](
                https://console.cloud.google.com/apis/library/firebaserules.googleapis.com)  - allows Data Theorem to view the rules for the Firebase projects
    *   [Cloud Functions API](
                https://console.cloud.google.com/apis/library/cloudfunctions.googleapis.com)  - allows Data Theorem to enumerate Cloud Functions
    *   [App Engine Admin API](
                https://console.cloud.google.com/apis/library/appengine.googleapis.com)  - allows Data Theorem to enumerate the deployed App Engine services so that we can discover APIs deployed with the Endpoints Framework
    *   [Identity and Access Management (IAM) API](
                https://console.cloud.google.com/apis/library/iam.googleapis.com)  - allows Data Theorem to determine which permissions each role contains
    *   [Cloud SQL Admin API](https://console.cloud.google.com/apis/library/sqladmin.googleapis.com) - allows Data Theorem to enumerate Cloud SQL instances
    *   [Kubernetes Engine API](https://console.cloud.google.com/apis/library/container.googleapis.com) - allows Data Theorem to enumerate Kubernetes clusters
    *   [Secret Manager API](https://console.cloud.google.com/apis/library/secretmanager.googleapis.com) - allows Data Theorem to enumerate secrets (note that we cannot access secrets value, only secrets metadata)
`;
