// @flow

import ImageBusiness from './ConfigurationsCloudStepperGcpMarkdownStep3ImageBusiness.svg';

export default `
After creating the service account, you'll need to add it to your organization, and provide the appropriate role permissions.
> These roles enable Data Theorem to scan your GCP environment. For more information on role permissions, see [Understanding Roles](https://cloud.google.com/iam/docs/understanding-roles#predefined_roles)

1.  Follow  [this link to the GCP IAM and admin page](https://console.cloud.google.com/iam-admin/iam)
2. In the top bar of the page, click the project selection dropdown (the down arrow).
    - Then, in the modal that appears, ensure that your organization is selected in "*Select From*".
    - Next click on the “*ALL*” tab  above the table, and select your Organization (look for the ![OrganizationIcon](${ImageBusiness}) icon) from the list of items. This will close the modal.
3.  Click **Add** near the top of the page, then in the drawer that appears, add the service account email from the last step in the *New Members* field.
4.  Click on **Select A Role** and then add roles for:
    - **Security Reviewer** - gives Data Theorem permissions to list all resources and Cloud IAM policies on them.
    - **Firebase Viewer** - gives Data Theorem read-only access to Firebase products.
    - **App Engine Viewer** - gives Data Theorem read-only access to all application configuration and settings.
    - **Service Controller** - gives Data Theorem runtime control of checking and reporting usage of a service.
6.  Click **Save** to finish adding the service account to your organization.
`;
