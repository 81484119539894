//@flow
import React, { type Node, type ElementConfig } from 'react';
import Typography from '@material-ui/core/Typography';

type Props = {|
  +title: Node,
  +style?: { ... },
  ...ElementConfig<typeof Typography>,
|};

const PageTitle = ({ title, style = {}, ...rest }: Props) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      style={{ marginTop: 15, ...style }}
      {...rest}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
