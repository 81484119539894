// @flow
import ImageCreateUser from './ConfigurationsCloudStepperKongMarkdownStep2ImageCreateUser.png';

export default (userToken: string) => `
1. Log into your Super Admin Kong account.
2. On the main management page, go to the Teams > RBAC Tokens section.
3. Select your workspace named default (it should be the one selected by default).
4. Click on the Add New User button:
5. Set a unique username.
6. As User token, use \`${userToken}\`.
> This token was uniquely generated and will be valid only for this session.


![Kong Steps](${ImageCreateUser})
`;
