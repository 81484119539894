// @flow

import React from 'react';
import { Card, Grid, Avatar, Tooltip } from '@material-ui/core';
import { palette } from '@dt/theme';
import { Link } from '@reach/router';
import DatatheoremLogo from '@dt/brand/logo.png';
import PolicyIcon from '@material-ui/icons/AssignmentTurnedIn';
import type { PolicyDecorated } from '@dt/horizon-api';
import type { PoliciesContainerQueryAhura_policies_list_policies } from '@dt/graphql-support/types/PoliciesContainerQueryAhura';
import type { Policy } from '@dt/graphql-support/types';
import Skeleton from '@material-ui/lab/Skeleton';
import GavelIcon from '@material-ui/icons/Gavel';
import { Text } from '@dt/material-components';

const styles = {
  assetCard: {
    marginBottom: 24,
    padding: 16,
  },
  avatar: { background: palette.brand35 },
  contentContainer: {},
  violationTagStrip: {
    display: 'flex',
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  text: {
    color: palette.gray20,
    margin: 0,
  },
  textCaption: {
    color: palette.gray35,
    margin: 0,
  },
};

type Props = {|
  policy?:
    | PolicyDecorated
    | PoliciesContainerQueryAhura_policies_list_policies
    | Policy,
  noLink?: boolean,
  isLoading?: boolean,
  product?: 'api' | 'web' | 'cloud',
  createdBy?: string,
|};

const PolicyCard = ({
  policy,
  noLink,
  isLoading,
  product,
  createdBy,
}: Props) => {
  const card = (
    <Card style={styles.assetCard}>
      <Grid container alignItems="flex-start" wrap="nowrap" spacing={2}>
        <Grid item>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="circle"
              height={40}
              width={40}
            />
          ) : (
            <Avatar style={{ backgroundColor: palette.gray45 }}>
              {product && product === 'web' ? (
                <GavelIcon style={{ color: palette.gray20 }} />
              ) : (
                <PolicyIcon style={{ color: palette.gray20 }} />
              )}
            </Avatar>
          )}
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item>
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <Text
                variant="body"
                component="div"
                style={{ ...styles.urlWrap, ...styles.text }}
              >
                {policy.name}{' '}
                {policy.is_special_dt_policy && (
                  <Tooltip title="This policy has been crafted by Data Theorem's security experts.">
                    <img
                      style={{
                        width: 18,
                        height: 18,
                        verticalAlign: 'middle',
                        objectFit: 'cover',
                      }}
                      src={DatatheoremLogo}
                    />
                  </Tooltip>
                )}
              </Text>
            )}
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={170} />
            ) : (
              <Text variant="body" style={styles.textCaption}>
                Created by{' '}
                {!createdBy
                  ? policy.created_by_user &&
                    `${policy.created_by_user.first_name} ${policy.created_by_user.last_name}`
                  : createdBy}{' '}
                on {new Date(policy.date_created).toLocaleDateString()}
              </Text>
            )}
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={120} />
            ) : (
              <Text variant="body" style={styles.text}>
                {policy.enabled_policy_rules_count} rules enabled
              </Text>
            )}
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={160} />
            ) : (
              <Text variant="body" style={styles.text}>
                {policy.protected_asset_groups_count} asset group
                {policy.protected_asset_groups_count !== 1 ? 's' : ''} protected
              </Text>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
  return noLink || !policy ? (
    card
  ) : (
    <Link
      to={
        product && product === 'web'
          ? `/web/policies/${policy.id}`
          : product === 'cloud'
          ? `/cloud/policies/${policy.id}`
          : `/api/policy/${policy.id}`
      }
    >
      {card}
    </Link>
  );
};

export default React.memo<Props>(PolicyCard);
