//@flow
import React, { memo, type Node } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { palette } from '@dt/theme';

type Props = {|
  +children?: ?Node,
  +urgent?: ?number,
  +important?: ?number,
  +proactive?: ?number,
  +resolved?: ?number,
|};

const DashboardHostPieChartTooltipComponent = function DashboardHostPieChartTooltip({
  children,
  urgent,
  important,
  proactive,
  resolved,
}) {
  return (
    <div>
      {typeof urgent === 'number' && (
        <TooltipSwatchRow color={palette.red} value={urgent} label="Urgent" />
      )}
      {typeof important === 'number' && (
        <TooltipSwatchRow
          color={palette.yellow}
          value={important}
          label="Important"
        />
      )}
      {typeof proactive === 'number' && (
        <TooltipSwatchRow
          color={palette.blue}
          value={proactive}
          label="Proactive"
        />
      )}
      {typeof resolved === 'number' && (
        <TooltipSwatchRow
          color={palette.green}
          value={resolved}
          label="Resolved"
        />
      )}
      {children}
    </div>
  );
};

function TooltipSwatch({ color }) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        backgroundColor: color,
        borderRadius: 3,
        marginRight: 5,
      }}
    />
  );
}

function TooltipSwatchRow({ color, label, value }) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <TooltipSwatch color={color} />
      </Grid>
      <Grid item>
        <Typography display="inline" variant="h4" style={{ marginRight: 5 }}>
          {value}
        </Typography>{' '}
        <Typography variant="body2" display="inline">
          {label} Asset{value !== 1 && 's'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export const DashboardHostPieChartTooltip = memo<Props>(
  DashboardHostPieChartTooltipComponent,
);
