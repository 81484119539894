//@flow
import React, { memo } from 'react';
import { palette } from '@dt/theme';
import { StepLabel } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

type Props = {|
  +step: number,
  +stepIndex: number,
|};

/*
 * Shows the user a success label when they arrive at this step.
 *
 * @param step - The step this label exists in, indexed starting at 1 *not* 0.
 * @param stepIndex - The current step the user is on, indexed starting at 0 *not* 1.
 */
const ConfigurationsCloudStepperSuccessLabelComponent = function ConfigurationsCloudStepperSuccessLabel({
  step,
  stepIndex,
}) {
  return (
    <StepLabel
      icon={
        <CheckCircle
          style={{
            color: step - 1 === stepIndex ? palette.brand : palette.gray35,
          }}
        />
      }
    >
      Success!
    </StepLabel>
  );
};

export const ConfigurationsCloudStepperSuccessLabel = memo<Props>(
  ConfigurationsCloudStepperSuccessLabelComponent,
);
