// @flow
import React from 'react';
import { ConfigurationsCloudStepperApigee } from './ConfigurationsCloudStepperApigee';
import { ConfigurationsCloudStepperAws } from './ConfigurationsCloudStepperAws';
import { ConfigurationsCloudStepperAxway } from './ConfigurationsCloudStepperAxway';
import { ConfigurationsCloudStepperAzure } from './ConfigurationsCloudStepperAzure';
import { ConfigurationsCloudStepperGcp } from './ConfigurationsCloudStepperGcp';
import { ConfigurationsCloudStepperIpRanges } from './ConfigurationsCloudStepperIpRanges';
import { ConfigurationsCloudStepperKong } from './ConfigurationsCloudStepperKong';
import { ConfigurationsCloudStepperMulesoft } from './ConfigurationsCloudStepperMulesoft';
import { ConfigurationsCloudStepperNetworkServices } from './ConfigurationsCloudStepperNetworkServices';
import { ConfigurationsCloudStepperOpenApiDefinitions } from './ConfigurationsCloudStepperOpenApiDefinitions';
import type { ConfigurationsCloudVariant } from '../types';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +variant: ConfigurationsCloudVariant,
  +onComplete: () => void,
|};

/*
 * Facade component used to aggregate a common inteface to allow the user to onboard a specified configuration.
 *
 * Most steppers follow a similar workflow but they all have different step content with various local state and
 * validation.
 *
 * @example - To use a stepper for apigee.
 *     <ConfigurationsCloudStepper variant="apigee" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for aws.
 *     <ConfigurationsCloudStepper variant="aws" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for axway.
 *     <ConfigurationsCloudStepper variant="axway" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for azure.
 *     <ConfigurationsCloudStepper variant="azure" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for gcp.
 *     <ConfigurationsCloudStepper variant="gcp" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for ip_ranges.
 *     <ConfigurationsCloudStepper variant="ip_ranges" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for kong.
 *     <ConfigurationsCloudStepper variant="kong" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for mulesoft.
 *     <ConfigurationsCloudStepper variant="mulesoft" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for network_services.
 *     <ConfigurationsCloudStepper variant="network_services" onComplete={handleOnComplete} />
 *
 * @example - To use a stepper for openapi_definitions.
 *     <ConfigurationsCloudStepper variant="openapi_definitions" onComplete={handleOnComplete} />
 */
const ConfigurationsCloudStepperComponent = function ConfigurationsCloudStepper(
  props: Props,
) {
  switch (props.variant) {
    case CloudAuthenticatorTypeValues.Apigee:
      return <ConfigurationsCloudStepperApigee onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.ApigeeOnPrem:
      return <ConfigurationsCloudStepperApigee onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.AmazonWebServices:
      return <ConfigurationsCloudStepperAws onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.Axway:
      return <ConfigurationsCloudStepperAxway onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.Azure:
      return <ConfigurationsCloudStepperAzure onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.GoogleCloudPlatform:
      return <ConfigurationsCloudStepperGcp onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.Kong:
      return <ConfigurationsCloudStepperKong onComplete={props.onComplete} />;
    case CloudAuthenticatorTypeValues.Mulesoft:
      return (
        <ConfigurationsCloudStepperMulesoft onComplete={props.onComplete} />
      );
    case 'ip_ranges':
      return <ConfigurationsCloudStepperIpRanges />;
    case 'network_services':
      return <ConfigurationsCloudStepperNetworkServices />;
    case 'openapi_definitions':
      return (
        <ConfigurationsCloudStepperOpenApiDefinitions
          onComplete={props.onComplete}
        />
      );
  }

  // Should never happen, props are explicitly required.
  // Any malformed props will be caught by the type checker.
  throw new Error(
    'No configurations cloud onboarding stepper component found.',
  );
};

export const ConfigurationsCloudStepper = ConfigurationsCloudStepperComponent;
