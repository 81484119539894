// @flow

import React from 'react';
import { Avatar, Card, Grid, Tooltip, Typography } from '@material-ui/core';

import { palette } from '@dt/theme';
import type { AssetGroupDecorated } from '@dt/horizon-api/types';
import type {
  PolicyQuery_policy_by_id_asset_groups,
  PolicyQuery_policy_by_id_users,
} from '@dt/graphql-support/types/PolicyQuery';
import AssetIcon from '@material-ui/icons/LibraryBooks';

import ApiIcon from '@material-ui/icons/DeviceHub';
import WebAppIcon from '@material-ui/icons/Web';
import APIDomainIcon from '@material-ui/icons/Language';
import OperationsIcon from '@material-ui/icons/Build';

import { Link } from '@reach/router';
export const styles = {
  assetCard: {
    height: 'auto',
    marginBottom: 24,
    padding: 16,
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
};

type Props = {|
  asset_group: AssetGroupDecorated | PolicyQuery_policy_by_id_asset_groups,
  createdBy?: PolicyQuery_policy_by_id_users,
  product?: 'api' | 'web' | 'cloud',
|};

const AssetGroupCard = ({ asset_group, createdBy, product }: Props) => (
  <Link
    to={
      product === 'cloud'
        ? `/cloud/inventory/asset-groups/${asset_group.id}`
        : product === 'web'
        ? `/web/inventory/asset-groups/${asset_group.id}`
        : `/api/asset-groups/${asset_group.id}`
    }
  >
    <Card style={styles.assetCard}>
      <Grid container alignItems="flex-start" wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar style={{ backgroundColor: palette.brand30 }}>
            <AssetIcon />
          </Avatar>
        </Grid>
        <Grid item container direction="row" spacing={1} wrap="nowrap">
          <Grid item xs={8}>
            <Typography variant="body1" component="div" style={styles.urlWrap}>
              {asset_group.name}
            </Typography>
            <Typography variant="body2">
              Created by{' '}
              {createdBy
                ? `${createdBy.first_name} 
              ${createdBy.last_name} `
                : asset_group.created_by_user &&
                  `${asset_group.created_by_user.first_name} 
              ${asset_group.created_by_user.last_name} `}
              on {new Date(asset_group.date_created).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={2}
            justify="flex-end"
            wrap="nowrap"
            alignItems="flex-start"
            style={{ marginLeft: 'auto' }}
          >
            <Tooltip title="API Operations">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <OperationsIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: palette.gray20,
                    marginRight: 3,
                  }}
                />{' '}
                <Typography
                  variant="body2"
                  component="span"
                  style={{ minWidth: 32 }}
                >
                  {
                    asset_group.asset_group_memberships_overview
                      .api_operations_count
                  }
                </Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Restful APIs">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <ApiIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: palette.gray20,
                    marginRight: 3,
                  }}
                />{' '}
                <Typography
                  variant="body2"
                  component="span"
                  style={{ minWidth: 32 }}
                >
                  {
                    asset_group.asset_group_memberships_overview
                      .restful_apis_count
                  }
                </Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Network Services">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <APIDomainIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: palette.gray20,
                    marginRight: 3,
                  }}
                />{' '}
                <Typography
                  variant="body2"
                  component="span"
                  style={{ minWidth: 32 }}
                >
                  {
                    asset_group.asset_group_memberships_overview
                      .network_services_count
                  }
                </Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Web Applications">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <WebAppIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: palette.gray20,
                    marginRight: 3,
                  }}
                />
                <Typography
                  variant="body2"
                  component="span"
                  style={{ minWidth: 32 }}
                >
                  {
                    asset_group.asset_group_memberships_overview
                      .web_applications_count
                  }
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  </Link>
);

export default React.memo<Props>(AssetGroupCard);
