//@flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';

const styles = {
  anything: {
    fontSize: 12,
  },
  margin: {
    margin: 4,
  },
  extendedIcon: {
    marginRight: 4,
  },
  label: {
    whiteSpace: 'nowrap',
  },
};

const DashboardHostPieChartAddAccessComponent = function DashboardHostPieChartAddAccess({
  classes,
}) {
  return (
    <Fab
      variant="extended"
      size="small"
      color="primary"
      aria-label="add"
      className={classes.margin}
      classes={{ label: classes.label }}
      style={{ paddingRight: '12px' }}
    >
      <ContentAdd
        className={classes.extendedIcon}
        style={{
          width: '22px',
          height: '22px',
          marginRight: '5px',
          color: 'inherit',
        }}
      />
      Add Access
    </Fab>
  );
};

export const DashboardHostPieChartAddAccess = withStyles<{||}, _>(styles)(
  DashboardHostPieChartAddAccessComponent,
);
