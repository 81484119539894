//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation MulesoftAuthenticatorsCreateMutation(
    $body: MulesoftAuthenticatorsCreateParams!
  ) {
    mulesoft_authenticators_create(body: $body) {
      id
      date_created
      added_by_user_id
      organization_id
    }
  }
`;

export default {
  create,
};
