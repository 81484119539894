//@flow
import { useMutation } from '@apollo/client';
import { useQuery } from '@dt/apollo-link-schema-rest';

import ip_ranges from '@dt/graphql-support/sevenhell/ip_ranges';
import ip_range_scans from '@dt/graphql-support/sevenhell/ip_range_scans';

import type {
  IpRangesListQuery,
  IpRangesListQueryVariables,
} from '@dt/graphql-support/types/IpRangesListQuery';
import type {
  IpRangesPatchMutation,
  IpRangesPatchMutationVariables,
} from '@dt/graphql-support/types/IpRangesPatchMutation';
import type {
  IpRangesCreateMutation,
  IpRangesCreateMutationVariables,
} from '@dt/graphql-support/types/IpRangesCreateMutation';
import type {
  IpRangeScansCreateMutation,
  IpRangeScansCreateMutationVariables,
  IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
  IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results,
} from '@dt/graphql-support/types/IpRangeScansCreateMutation';

/*
 * Ip address ranges controller used in conjunctoin with ip ranges scanner components.
 */
export const useIpRanges = () => {
  const {
    error: listIpAddressRangeError,
    loading: listIpAddressRangeLoading,
    data: listIpAddressRangeData,
    fetchMore: listIpAddressRangeFetchMore,
    pageNumber: listIpAddressRangePageNumber,
    refetch: listIpAddressRangeRefetch,
  } = useQuery<IpRangesListQuery, IpRangesListQueryVariables>(ip_ranges.list);

  const [
    patchIpAddressRange,
    { loading: patchIpAddressRangeLoading, error: patchIpAddressRangeError },
  ] = useMutation<IpRangesPatchMutation, IpRangesPatchMutationVariables>(
    ip_ranges.patch,
  );

  const [
    createIpAddressRange,
    {
      data: createIpAddressRangeData,
      error: createIpAddressRangeError,
      loading: createIpAddressRangeLoading,
    },
  ] = useMutation<IpRangesCreateMutation, IpRangesCreateMutationVariables>(
    ip_ranges.create,
  );

  const [
    scanIpAddressRange,
    {
      data: scanIpAddressRangeData,
      loading: scanIpAddressRangeLoading,
      error: scanIpAddressRangeError,
    },
  ] = useMutation<
    IpRangeScansCreateMutation,
    IpRangeScansCreateMutationVariables,
  >(ip_range_scans.create);

  // The IP range to determine what was scanned and the result.
  let ip_range = null;
  let ip_range_scan = null;
  if (
    scanIpAddressRangeData &&
    scanIpAddressRangeData.ip_range_scans_create.ip_range_scans[0]
  ) {
    ip_range = scanIpAddressRangeData.ip_range_scans_create.ip_ranges[0];
    ip_range_scan =
      scanIpAddressRangeData.ip_range_scans_create.ip_range_scans[0];
  }

  return [
    /*
     * Wrapper around `createIpAddressRange`.
     *
     * Creates an ip address range to add to the user's inventory.
     */
    (
      ip_range_scan: IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
      continuousDiscoveryEnabled: boolean,
      selectedIpRangeScanResults: $ReadOnlyArray<IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results>,
    ) => {
      createIpAddressRange({
        variables: {
          body: {
            ip_range: ip_range_scan.ip_range,
            has_continuous_discovery_enabled: continuousDiscoveryEnabled,
            ip_addresses_to_import: !continuousDiscoveryEnabled
              ? selectedIpRangeScanResults.map(
                  ip_address_scan_result => ip_address_scan_result.ip_address,
                )
              : null,
          },
        },
      });
    },
    {
      ip_range,
      ip_range_scan,
      createIpAddressRangeData,
      scanIpAddressRange,
      scanIpAddressRangeData,
      scanIpAddressRangeLoading,
      scanIpAddressRangeError,
      listIpAddressRangeData,
      listIpAddressRangeLoading,
      listIpAddressRangeError,
      listIpAddressRangePageNumber,
      listIpAddressRangeFetchMore,
      listIpAddressRangeRefetch,
      createIpAddressRangeLoading,
      createIpAddressRangeError,
      patchIpAddressRange,
      patchIpAddressRangeLoading,
      patchIpAddressRangeError,
    },
  ];
};
