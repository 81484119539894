// @flow
import { type CloudAuthenticatorType } from '@dt/graphql-support/types';

export let AssetTypeDict = {
  RESTFUL_API: ('RESTFUL_API': 'RESTFUL_API'),
  SINGLE_PAGE_WEB_APP: ('SINGLE_PAGE_WEB_APP': 'SINGLE_PAGE_WEB_APP'),
  API_DOMAIN: ('API_DOMAIN': 'API_DOMAIN'),
  CLOUD_RESOURCE: ('CLOUD_RESOURCE': 'CLOUD_RESOURCE'),
  GRAPHQL_API: ('GRAPHQL_API': 'GRAPHQL_API'),
};

export type ConfigurationsCloudVariant =
  | CloudAuthenticatorType
  | 'ip_ranges'
  | 'network_services'
  | 'openapi_definitions';
