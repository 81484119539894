// @flow
import React, { memo } from 'react';
import { Card, Grid } from '@material-ui/core';
import BlackboxReverseEngineeringDiagram from '../svg_components/BlackboxReverseEngineeringDiagram';
import { ConfigurationsCloudDataSourcesTableAutomatic } from './ConfigurationsCloudDataSourcesTableAutomatic';
import { ConfigurationsCloudDataSourcesTableCloud } from './ConfigurationsCloudDataSourcesTableCloud';
import { ConfigurationsCloudDataSourcesTableApiGateways } from './ConfigurationsCloudDataSourcesTableApiGateways';
import { ConfigurationsCloudDataSourcesTableAddAccess } from './ConfigurationsCloudDataSourcesTableAddAccess';

type Props = {|
  +excludeConfiguredSources?: boolean,
|};

/*
 * Shows the user a table of all configurations related to cloud and the ability to update the ones that can be configured.
 *
 * @param excludeConfiguredSources - Excludes configurations from the table that have already been configured.
 */
const ConfigurationsCloudDataSourcesTableComponent = function ConfigurationsCloudDataSourcesTable({
  excludeConfiguredSources,
}: Props) {
  return (
    <Grid container direction="row" justify="center" spacing={2}>
      <Grid item xs={12}>
        <Card>
          <BlackboxReverseEngineeringDiagram />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <ConfigurationsCloudDataSourcesTableAutomatic
            includeDateAddedHeader
            includeDiscoveryStatusHeader
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <ConfigurationsCloudDataSourcesTableCloud
            includeDateAddedHeader
            includeDiscoveryStatusHeader
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <ConfigurationsCloudDataSourcesTableApiGateways
            includeDateAddedHeader
            includeDiscoveryStatusHeader
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <ConfigurationsCloudDataSourcesTableAddAccess
            variant="asm"
            excludeConfiguredVariants={excludeConfiguredSources}
            includeDateAddedHeader
            includeDiscoveryStatusHeader
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export const ConfigurationsCloudDataSourcesTable = memo<Props>(
  ConfigurationsCloudDataSourcesTableComponent,
);
