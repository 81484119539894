//@flow
import React from 'react';
import { Typography } from '@material-ui/core';

export const IpRangesFooter = () => {
  return (
    <Typography variant="body2" gutterBottom>
      IP Addresses can be discovered once or setup to continuously discover
      daily with &quot;Continuous Discovery&quot; enabled.
    </Typography>
  );
};
