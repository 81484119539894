// @flow

export default `
To assign permissions to the custom role:

1. Select the __API Manager__ tab under __Permissions__ in your custom role.

2. Select the environment from the __Add environment by name__ field for the environment you want the analyzer to have access to.

   __Note__: You’ll have to do steps 2-4 for each environment you want the analyzer to have access to.

3. Once an environment has been selected, select the following permissions from the __Select access__ field.

   - View APIs Configuration
   - View Contracts
   - View Policies

4. After permissions have been selected, click the __Blue Plus Icon__ on the right hand side.

5. Select the __Runtime Manager__ tab under __Permissions__ in your custom role.

6. In the first section there is a table with one heading marked as __Permissions__
   select the following permissions from the __Select access__ field.

   - Cloudhub Network Viewer

7. In the second section there is a table with headings __Environment__ and __Permissions__
   select the environment from the __Add environment by name__ field for the environment you want the analyzer to have access to.

   __Note__: You'll have to do steps 7-9 for each environment you want the analyzer to have access to.

8. Once an environment has been selected, select the following permissions from the __Select access__ field.

   - Read Alerts
   - Read Applications
   - Read Servers
   - Download Applications

9. After permissions have been selected, click the __Blue Plus Icon__ on the right hand side.

10. Select the __Design Center__ tab under __Permissions__ in your custom role.

11. Select the following permissions from the __Select access__ field.

    - Design Center Developer

12. After permissions have been selected, click the __Blue Plus Icon__ on the right hand side.

13. At this point all permissions should now be properly setup for the custom role!

#### MuleSoft Docs:

- [Access Management Permissions Explained](https://support.mulesoft.com/s/article/Anypoint-Platform-permissions-explanation)
`;
