// @flow

export default `
To setup a custom role:

1. Login into your MuleSoft Account through the [AnypointPlatform portal](https://anypoint.mulesoft.com/home/)

2. Under the title **Management Center** select **Access Management**

   **Note**: If you can’t find **Access Management** then you might not have access to set this up.

3. Select **Roles** from the left side menu

4. Click the **Add role** button

5. In the dialog box for the **Role Name** field enter

   - DataTheoremAnalyzer

6. In the dialog box for the **Role Description** field enter

   - Data Theorem custom role to analyze and inspect environments

#### MuleSoft Docs:

- [Access Management](https://docs.mulesoft.com/access-management/managing-your-account)
- [Access Management Roles](https://docs.mulesoft.com/access-management/roles)
  `;
