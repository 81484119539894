//@flow
import React, { useState } from 'react';
import values from 'lodash/fp/values';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core';
import { Message } from './../Message';
import IpRangesContinuousDiscoveryModal, {
  IpRangesContinuousDiscoveryModalButtons,
} from './IpRangesContinuousDiscoveryModal';
import IpRangesScanResults from './IpRangesScanResults';

import type {
  IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
  IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results,
} from '@dt/graphql-support/types/IpRangeScansCreateMutation';

type Props = {|
  +showResultsAsCards?: boolean,
  +canAddIpAddressRange: boolean,
  +ip_range_scan: IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
  +onAddScanResults: (
    ip_range_scan: IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
    continuousDiscoveryEnabled: boolean,
    selectedIpRangeScanResults: Array<IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results>,
  ) => void,
|};

/*
 * Shows the user ip address range scan details.
 *
 * @param showResultsAsCards - Whether or not to render the ip address range scan resullts as cards or a list
 * @param canAddIpAddressRange - Whether or not the user can add an ip address range
 * @param ip_range_scan - Scan details provided by the backend
 * @param onAddScanResults - Triggered event when the user adds the scan results to their inventory
 *
 * @example
 *     <IpRangesScanDetails
 *       showResultsAsCards
 *       canAddIpAddressRange={!isPageLoading && !isPageError}
 *       ip_range_scan={ip_range_scan}
 *       onAddScanResults={handleOnAddScanResults} />
 */
export const IpRangesScanDetails = ({
  showResultsAsCards,
  canAddIpAddressRange,
  ip_range_scan,
  onAddScanResults,
}: Props) => {
  // Used to select all ip range scan results.
  const allSelectedIpRangeScanResultsSelected = ip_range_scan.ip_address_scan_results.reduce(
    (aggregated, ip_address_scan_result) => ({
      ...aggregated,
      [ip_address_scan_result.ip_address]: true,
    }),
    {},
  );

  const [
    enableContinuousDiscoveryOption,
    setEnableContinuousDiscoveryOption,
  ] = useState<boolean>(false);
  const [
    showContinuousDiscoveryModal,
    setShowContinuousDiscoveryModal,
  ] = useState<boolean>(false);

  // Lookup key is a ip range scan result ip address.
  const [selectedIpRangeScanResults, setSelectedIpRangeScanResults] = useState<{
    [string]: boolean,
    ...,
  }>(allSelectedIpRangeScanResultsSelected);

  // Check all ip address in ip address range when turning continuous discovery option on.
  const handleSetEnableContinuousDiscoveryOption = enabled => {
    if (enabled) {
      setSelectedIpRangeScanResults(allSelectedIpRangeScanResultsSelected);
    }

    setEnableContinuousDiscoveryOption(enabled);
  };

  // Only show dialog when enabling continuous discovery.
  const handleContinuousDiscoveryCheckboxOnChange = () => {
    if (!enableContinuousDiscoveryOption) {
      setShowContinuousDiscoveryModal(true);
    } else {
      handleSetEnableContinuousDiscoveryOption(false);
    }
  };

  // Set continuous discovery option from modal result.
  const handleContinuousDiscoveryOnModalClosed = ({
    button,
  }: {|
    +button: $Keys<typeof IpRangesContinuousDiscoveryModalButtons>,
  |}) => {
    handleSetEnableContinuousDiscoveryOption(
      button === IpRangesContinuousDiscoveryModalButtons.ENABLE,
    );
    setShowContinuousDiscoveryModal(false);
  };

  return (
    <>
      {/* Controls */}
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item>
          <Button
            variant="contained"
            disabled={
              canAddIpAddressRange &&
              !enableContinuousDiscoveryOption &&
              !values(selectedIpRangeScanResults).filter(Boolean).length
            }
            onClick={() => {
              const selectedIpRangeScanResultAddresses = Object.keys(
                selectedIpRangeScanResults,
              ).filter(ip_address => selectedIpRangeScanResults[ip_address]);
              onAddScanResults(
                ip_range_scan,
                enableContinuousDiscoveryOption,
                ip_range_scan.ip_address_scan_results.filter(
                  ip_address_scan_result =>
                    selectedIpRangeScanResultAddresses.includes(
                      ip_address_scan_result.ip_address,
                    ),
                ),
              );
            }}
          >
            {enableContinuousDiscoveryOption
              ? 'Add all IPs daily'
              : 'Add Selected IPs'}
          </Button>
        </Grid>

        <Grid item>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableContinuousDiscoveryOption}
                  onChange={handleContinuousDiscoveryCheckboxOnChange}
                  color="primary"
                />
              }
              label="Use Continuous Discovery?"
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Modal */}
      <IpRangesContinuousDiscoveryModal
        ip_range={ip_range_scan.ip_range}
        open={showContinuousDiscoveryModal}
        onModalClosed={handleContinuousDiscoveryOnModalClosed}
      />

      {/* Scan Results */}
      {ip_range_scan.ip_address_scan_results.length <= 0 ? (
        <Message
          m={0}
          variant="error"
          message="No scannable domains found on that range."
        />
      ) : (
        <IpRangesScanResults
          showResultsAsCards={showResultsAsCards}
          ip_address_scan_results={ip_range_scan.ip_address_scan_results}
          allowSelection={!enableContinuousDiscoveryOption}
          selectedIpRangeScanResults={selectedIpRangeScanResults}
          onIpRangeScanResultToggled={(ip_range_scan_result, selected) =>
            setSelectedIpRangeScanResults({
              ...selectedIpRangeScanResults,
              [ip_range_scan_result.ip_address]: selected,
            })
          }
        />
      )}
    </>
  );
};

export default IpRangesScanDetails;
