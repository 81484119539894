//@flow
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useQuery } from '@dt/apollo-link-schema-rest';
import openapi_definitions from '@dt/graphql-support/horizon/openapi_definitions';
import support_requests from '@dt/graphql-support/horizon/support_requests';
import { parseOpenAPIDefinitionFile } from './util';

import type {
  OpenApiDefinitionsCreateMutation,
  OpenApiDefinitionsCreateMutationVariables,
} from '@dt/graphql-support/types/OpenApiDefinitionsCreateMutation';
import type {
  OpenApiDefinitionsListQuery,
  OpenApiDefinitionsListQueryVariables,
} from '@dt/graphql-support/types/OpenApiDefinitionsListQuery';
import type {
  SupportRequestsCreateMutation,
  SupportRequestsCreateMutationVariables,
} from '@dt/graphql-support/types/SupportRequestsCreateMutation';

/*
 * Open API definitions upload controller that wraps the gql `createOpenApiDefinition` mutation with parsing validation
 * logic.
 */
export const useOpenApiDefinitionsUpload = () => {
  const [
    createOpenApiDefinition,
    {
      data: createOpenApiDefinitionData,
      loading: createOpenApiDefinitionLoading,
      error: createOpenApiDefinitionError,
    },
  ] = useMutation<
    OpenApiDefinitionsCreateMutation,
    OpenApiDefinitionsCreateMutationVariables,
  >(openapi_definitions.create);
  const { refetch: openApiDefinitionsListRefetch } = useQuery<
    OpenApiDefinitionsListQuery,
    OpenApiDefinitionsListQueryVariables,
  >(openapi_definitions.list);

  const [
    createSupportRequest,
    { loading: createSupportRequestLoading, error: createSupportRequestError },
  ] = useMutation<
    SupportRequestsCreateMutation,
    SupportRequestsCreateMutationVariables,
  >(support_requests.create);

  const [errorParsing, setErrorParsing] = useState<null | {|
    +message: string,
  |}>(null);

  return [
    /*
     * Wrapper around `createOpenApiDefinition`.
     *
     * Uploads an openapi definition file object to horizon after successfull parsing it.
     * Will also send a support request with additional details about the uploaded api definition.
     */
    async ({
      file,
      apiUrl,
      curlCommand,
      comments,
    }: {|
      +file: ?File,
      +apiUrl: string,
      +curlCommand: string,
      +comments: string,
    |}) => {
      if (!file) return;

      // Parse file to see if its a valid open api definition.
      const file_parsed = await parseOpenAPIDefinitionFile(file);
      if (typeof file_parsed !== 'string') {
        setErrorParsing({ message: file_parsed.error });
      } else {
        setErrorParsing(null);

        // Create open api definition.
        await createOpenApiDefinition({
          variables: {
            body: file_parsed,
          },
        });

        // Create corresponding support request - only if we have additional details.
        if (apiUrl || curlCommand || comments) {
          await createSupportRequest({
            variables: {
              body: {
                subject: 'API Credentials submitted',
                body: JSON.stringify({
                  apiURL: apiUrl,
                  CURLCommand: curlCommand,
                  comments: comments,
                }),
              },
            },
          });
        }

        // Refetch to update cache.
        await openApiDefinitionsListRefetch();
      }
    },
    {
      data: createOpenApiDefinitionData,
      loading: createOpenApiDefinitionLoading || createSupportRequestLoading,
      error:
        errorParsing ||
        createOpenApiDefinitionError ||
        createSupportRequestError,
    },
  ];
};
