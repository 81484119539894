// @flow
import { type Node } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  children: Node,
  fallback: Node,
};

/**
 * The purpose of this component is only to wait for the saga that handles
 * creating a *horizon* session to finish.
 *
 * In apps that require to talk to apis other than seven-hell the created
 * session right after the login is *not* sufficient and this session needs
 * to be cached on the horizon or caprica or any other server to be usable
 * as authentication header.
 *
 * For example, if you navigate to a page that calls horizon for some data
 * but the session hasn't been create with horizon you'll get a 401 error.
 * The `containerFlow` saga located at apps/ahura/src/containers/sagas.js
 * takes care of the creating session with horizon but since it runs
 * independently and in parallel, we are not guaranteed to have a usable
 * session when the `RequiresUserAccountOrRedirect` is rendered.
 *
 * In order to *wait and then* render we use this component that selects
 * the relevant part of the redux store and only renders when the condition
 * is met but a better solution is to handle creating a session along with
 * fetching user account so one component can handle all this without any
 * issue. This ia how it's been done in brandportect and had worked properly
 * for a long time.
 */
function WaitForSession({ children, fallback }: Props) {
  let session = useSelector(s => s.user_sessions);
  return session.success ? children : fallback;
}

export default WaitForSession;
