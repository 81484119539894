//@flow
import { type Node, useEffect, useState } from 'react';
import { getUserAccount, REJECT_REASON, type UserAccount } from './userAccount';
import { clearSessionAndRedirectToExpired, redirectToLogin } from './redirect';

type Props = {
  children: ({ userAccount: null | UserAccount }) => Node,
};

export default function RequiresUserAccountOrRedirect({ children }: Props) {
  const [userAccount, setUserAccount] = useState(null);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    getUserAccount()
      .then(result => {
        if (!isMounted) {
          return;
        }

        if (!result.no_session_reason) {
          setUserAccount(result);
          return;
        }

        if (result.no_session_reason === REJECT_REASON.NO_SESSION_ID) {
          redirectToLogin();
        } else if (
          result.no_session_reason === REJECT_REASON.EXPIRED_SESSION_ID
        ) {
          clearSessionAndRedirectToExpired();
        } else {
          setHasError(true);
        }
      })
      .catch(() => setHasError(true));

    return () => {
      isMounted = false;
    };
  }, []);

  if (hasError) {
    throw new Error('There was an error when trying to get the session');
  }

  return children({ userAccount: userAccount });
}
