//@flow
import React, { memo } from 'react';
import { Link } from '@reach/router';
import {
  Grid,
  Breadcrumbs as MuiBreadcumbs,
  Typography,
} from '@material-ui/core';
import ExtLink from './ExtLink';

type Props = {
  locations: $ReadOnlyArray<
    | {
        title: string,
        currentPath: true,
      }
    | {
        title: string,
        path: string,
        isExternalLink?: boolean,
      },
  >,
};

/*
 * Allows the user to navigate to previous pages related to the current page.
 *
 * @param locations - List of crumbs
 * @param locations.title - Crumb title to render
 *
 * @param locations.currentPath - Crumb is the current path, usually the last crumb
 *
 * @param locations.path - Crumb routing information to route the user to
 * @param locations.isExternalLink - Whether or not to use ExtLink or not.
 *                                   TODO@nw: When apps are merged this should be deprecated.
 */
const BreadcrumbsComponent = function Breadcrumbs({ locations }: Props) {
  return (
    <Grid container>
      <MuiBreadcumbs aria-label="breadcrumb">
        {locations.map((location, i) => {
          if (location.path) {
            return !location.isExternalLink ? (
              <Link to={location.path} key={i}>
                <Typography color="textSecondary">{location.title}</Typography>
              </Link>
            ) : (
              <ExtLink to={location.path} target="_self" key={i}>
                <Typography color="textSecondary">{location.title}</Typography>
              </ExtLink>
            );
          }

          return (
            <Typography color="textPrimary" key={i}>
              {location.title}
            </Typography>
          );
        })}
      </MuiBreadcumbs>
    </Grid>
  );
};

export const Breadcrumbs = memo<Props>(BreadcrumbsComponent);
