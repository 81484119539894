// @flow
import config from '@dt/config';

const accountId =
  typeof config.awsClientId === 'string' ? config.awsClientId : '--------';

export default (externalID: string) => `
1. Navigate to Create Role page on AWS [by clicking here](https://console.aws.amazon.com/iam/home?#/roles$new?step=type&roleType=crossAccount&isThirdParty&accountID=${accountId}&externalID=${externalID})
   - The link will pre-fill Data Theorem's account ID and external ID
2. Ensure the field Account ID and External ID are pre-filled
2. Select **Next: Permissions**
3. Enter \`SecurityAudit\` in the search box and then select its checkbox
4. Erase the search box, and enter \`DataTheorem-APIGateway-SecurityAudit\`. Select its checkbox
5. Select **Next: Review** and enter the following for the name:
    - \`DataTheorem-Service\`
    - Ensure it has the two **SecurityAudit** and **DataTheorem-APIGateway-SecurityAudit** policies enabled
6. Select **Create role**
7. Select on the newly created role \`DataTheorem-Service\`
8. Copy the **Role ARN** value on the top of the page and come back to this page and select **Next**
9. Paste the **Role ARN** and select **Test Credentials**
    - Select **Complete**

Should you have any issues, contact support@datatheorem.com
`;
