// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import apigee_authenticators from '@dt/graphql-support/horizon/apigee_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperApigeeMarkdownStep1 from './ConfigurationsCloudStepperApigeeMarkdownStep1';
import configurationsCloudStepperApigeeMarkdownStep2 from './ConfigurationsCloudStepperApigeeMarkdownStep2';

import type {
  ApigeeAuthenticatorsCreateMutation,
  ApigeeAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/ApigeeAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Apigee authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperApigeeComponent = function ConfigurationsCloudStepperApigee({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [organizationName, setOrganizationName] = useState('');

  // Apigee authenticator create mutation.
  const [
    createApigeeAuthenticator,
    {
      loading: createApigeeAuthenticatorLoading,
      error: createApigeeAuthenticatorError,
    },
  ] = useMutation<
    ApigeeAuthenticatorsCreateMutation,
    ApigeeAuthenticatorsCreateMutationVariables,
  >(apigee_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeOrganizationName = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setOrganizationName(value);
  };
  const handleOnSubmitForm = async () => {
    if (organizationName) {
      const { data, errors } = await createApigeeAuthenticator({
        variables: {
          body: {
            organization: organizationName,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  // Form validation.
  const isOrganizationNameValidRequired = organizationName.length > 0;

  return (
    <section
      style={{
        width: '100%',
        margin: '0 auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0 auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Create read-only role for Data Theorem</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperApigeeMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Verify Role Connection</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperApigeeMarkdownStep2} />
            </div>

            <div style={{ marginBottom: 16 }}>
              {createApigeeAuthenticatorError && (
                <Message
                  variant="error"
                  message={createApigeeAuthenticatorError.message}
                />
              )}

              <TextField
                variant="outlined"
                label="Organization Name"
                error={!isOrganizationNameValidRequired}
                helperText={
                  isOrganizationNameValidRequired
                    ? null
                    : 'Please enter an Organization name'
                }
                autoFocus
                fullWidth
                value={organizationName}
                onChange={handleOnChangeOrganizationName}
                style={{ display: 'block', marginTop: 20 }}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              prevDisabled={createApigeeAuthenticatorLoading}
              nextDisabled={
                !isOrganizationNameValidRequired ||
                createApigeeAuthenticatorLoading
              }
              nextLabel={
                createApigeeAuthenticatorLoading
                  ? 'Testing...'
                  : createApigeeAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={3}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.Apigee}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperApigee = ConfigurationsCloudStepperApigeeComponent;
