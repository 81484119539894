//@flow
import React from 'react';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';
import type { ConfigurationsCloudVariant } from '../types';

type Props = {|
  +variant: ConfigurationsCloudVariant,
  +fullName?: boolean,
|};

export const ConfigurationsCloudNameIdentifierComponent = function ConfigurationsCloudNameIdentifier({
  variant,
  fullName,
}: Props) {
  let display = null;

  switch (variant) {
    case CloudAuthenticatorTypeValues.Apigee:
      display = fullName ? 'Apigee SaaS' : 'Apigee';
      break;
    case CloudAuthenticatorTypeValues.ApigeeOnPrem:
      display = fullName ? 'Apigee SaaS' : 'Apigee';
      break;
    case CloudAuthenticatorTypeValues.AmazonWebServices:
      display = fullName ? 'Amazon Web Services (AWS)' : 'AWS';
      break;
    case CloudAuthenticatorTypeValues.Axway:
      display = fullName ? 'Axway AMPLIFY' : 'Axway';
      break;
    case CloudAuthenticatorTypeValues.Azure:
      display = fullName ? 'Azure' : 'Azure';
      break;
    case CloudAuthenticatorTypeValues.GoogleCloudPlatform:
      display = fullName ? 'Google Cloud Platform (GCP)' : 'GCP';
      break;
    case 'ip_ranges':
      display = fullName ? 'IP Address Range' : 'IP';
      break;
    case CloudAuthenticatorTypeValues.Kong:
      display = fullName ? 'Kong Cloud' : 'Kong';
      break;
    case CloudAuthenticatorTypeValues.Mulesoft:
      display = fullName ? 'Mulesoft' : 'Mulesoft';
      break;
    case 'network_services':
      display = fullName ? 'Web URL' : 'Domain';
      break;
    case 'openapi_definitions':
      display = fullName ? 'OpenAPI Definition' : 'OpenAPI';
      break;
  }

  if (display === null) {
    throw new Error(
      `No configurations cloud display name found for variant '${variant}'.`,
    );
  }

  return <>{display}</>;
};

export const ConfigurationsCloudNameIdentifier = ConfigurationsCloudNameIdentifierComponent;
