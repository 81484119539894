//@flow
import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
} from '@material-ui/core';
import MobileAppIcon from '@material-ui/icons/DeveloperMode';
import WebAppScanIcon from '@material-ui/icons/Dvr';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import CertTransLogo from './ConfigurationsCloudDataSourcesTableAutomaticImageCertificateTransparency.svg';
import Text from '../Text';

const useStyles = makeStyles({
  materialIconStyle: { color: palette.gray20 },
  avatar: {
    backgroundColor: palette.gray45,
    padding: 5,
    marginRight: 12,
  },
  table: {
    backgroundColor: palette.white,
  },
  tableCellStyle: {
    paddingRight: '16px !important',
  },
  onlineIndicator: {
    textTransform: 'uppercase',
    color: palette.green20,
    backgroundColor: palette.green50,
    borderRadius: 2,
    padding: '2px 4px',
  },
  offlineIndicator: {
    textTransform: 'uppercase',
    color: palette.red20,
    backgroundColor: palette.red50,
    borderRadius: 2,
    padding: '2px 4px',
  },
  descriptorCellContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {|
  +includeDateAddedHeader?: boolean,
  +includeDiscoveryStatusHeader?: boolean,
|};

const ConfigurationsCloudDataSourcesTableAutomaticComponent = function ConfigurationsCloudDataSourcesTableAutomatic({
  includeDateAddedHeader,
  includeDiscoveryStatusHeader,
}: Props) {
  const classes = useStyles();

  const automaticDataSources = {
    certlog: {
      id: 'certlog',
      name: 'Public Internet Analyzer',
      icon: <Avatar className={classes.avatar} src={CertTransLogo} />,
      online: true,
      dateAdded: 'Automatic',
    },
    mobile: {
      id: 'mobile',
      name: 'Mobile Application Analyzer',
      icon: (
        <Avatar className={classes.avatar}>
          <MobileAppIcon className={classes.materialIconStyle} />
        </Avatar>
      ),
      online: true,
      dateAdded: 'Automatic',
    },
    web: {
      id: 'web',
      name: 'Web Application Analyzer',
      icon: (
        <Avatar className={classes.avatar}>
          <WebAppScanIcon className={classes.materialIconStyle} />
        </Avatar>
      ),
      online: true,
      dateAdded: 'Automatic',
    },
  };

  return (
    <Table className={classes.table}>
      <colgroup>
        <col style={{ width: '60%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>

      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellStyle}>
            <Text style={{ margin: 0 }} variant="titleXS">
              Automatic Sources
            </Text>
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            {includeDateAddedHeader && (
              <Text style={{ margin: 0 }} variant="titleXS">
                Date Added
              </Text>
            )}
          </TableCell>

          <TableCell className={classes.tableCellStyle}>
            {includeDiscoveryStatusHeader && (
              <Text style={{ margin: 0 }} variant="titleXS">
                Status
              </Text>
            )}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.keys(automaticDataSources).map(auth => (
          <TableRow key={auth}>
            <TableCell className={classes.tableCellStyle}>
              <div className={classes.descriptorCellContent}>
                {automaticDataSources[auth].icon}
                <Text style={{ margin: 0 }} variant="body">
                  {automaticDataSources[auth].name}
                </Text>
              </div>
            </TableCell>

            <TableCell className={classes.tableCellStyle}>
              <Text style={{ margin: 0 }} variant="body">
                {automaticDataSources[auth].dateAdded}
              </Text>
            </TableCell>

            <TableCell className={classes.tableCellStyle}>
              {automaticDataSources[auth].online ? (
                <span className={classes.onlineIndicator}>Online</span>
              ) : (
                <span className={classes.offlineIndicator}>Offline</span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const ConfigurationsCloudDataSourcesTableAutomatic = ConfigurationsCloudDataSourcesTableAutomaticComponent;
