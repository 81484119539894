// @flow

export default `
After adding *cloud@datatheorem.com* as a new user to your Apigee Organization, paste your Organization's name below and submit to verify the connection.

You can find your organization's name as part of the URL to your API proxies, typically in the following format: 

\`\`\`javascript
http://org-name-env.apigee.net/v1/weather/forecastrss
\`\`\`

where:
- *org-name* is the name of your organization.
- *env* is the deployment environment of the API proxy, which is either test or prod.

For a full explanation, see the [*Organization Names* section of the Apigee documentation](https://docs.apigee.com/api-platform/fundamentals/apigee-edge-organization-structure#organizationcomponents-organizationnames)
`;
