//@flow
import React from 'react';
import { Avatar } from '@material-ui/core';
import { palette } from '@dt/theme';
import Apigee from './ConfigurationsCloudAvatarApigee.svg';
import Aws from './ConfigurationsCloudAvatarAws.svg';
import Axway from './ConfigurationsCloudAvatarAxway.svg';
import Azure from './ConfigurationsCloudAvatarAzure.svg';
import Gcp from './ConfigurationsCloudAvatarGcp.svg';
import IpAddress from './ConfigurationsCloudAvatarIpRange.svg';
import Kong from './ConfigurationsCloudAvatarKong.svg';
import Mulesoft from './ConfigurationsCloudAvatarMulesoft.svg';
import Network from './ConfigurationsCloudAvatarNetworkService.svg';
import OpenApi from './ConfigurationsCloudAvatarOpenapiDefinition.png';
import type { ConfigurationsCloudVariant } from '../types';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +variant: ConfigurationsCloudVariant,
  +alt?: string,
  +size?: 'small' | 'large',
  +style?: mixed,
|};

/*
 * Display a configurations cloud avatar icon by its variant.
 *
 * @param variant - Variant to render
 * @param alt - img tag alt attribute
 * @param size - How big the avatar should be
 * @param style - Override styles
 */
const ConfigurationsCloudAvatarComponent = function ConfigurationsCloudAvatar({
  variant,
  alt,
  size,
  style,
}: Props) {
  return (
    <Avatar
      style={{
        backgroundColor: palette.gray45,
        padding: 5,
        ...(size === 'large'
          ? {
              width: 150,
              height: 150,
            }
          : {}),
        ...style,
      }}
      src={getConfigurationsCloudIcon(variant)}
      alt={typeof alt !== 'undefined' ? alt : `${variant} Avatar`}
    />
  );
};

/*
 * This method is designed only to be used by the `ConfigurationsCloudAvatar` component.
 *
 * It is only exported here to support usage of `HostPieChart` which should probably use a
 * `ConfigurationsCloudSvg` component eventually instead.
 */
export const getConfigurationsCloudIcon = (
  variant: ConfigurationsCloudVariant,
) => {
  let icon = null;

  switch (variant) {
    case CloudAuthenticatorTypeValues.Apigee:
      icon = Apigee;
      break;
    case CloudAuthenticatorTypeValues.ApigeeOnPrem:
      icon = Apigee;
      break;
    case CloudAuthenticatorTypeValues.AmazonWebServices:
      icon = Aws;
      break;
    case CloudAuthenticatorTypeValues.Axway:
      icon = Axway;
      break;
    case CloudAuthenticatorTypeValues.Azure:
      icon = Azure;
      break;
    case CloudAuthenticatorTypeValues.GoogleCloudPlatform:
      icon = Gcp;
      break;
    case CloudAuthenticatorTypeValues.Kong:
      icon = Kong;
      break;
    case CloudAuthenticatorTypeValues.Mulesoft:
      icon = Mulesoft;
      break;
    case 'ip_ranges':
      icon = IpAddress;
      break;
    case 'network_services':
      icon = Network;
      break;
    case 'openapi_definitions':
      icon = OpenApi;
      break;
  }

  if (icon === null) {
    throw new Error(
      `No configurations cloud avatar icon found for variant '${variant}'.`,
    );
  }

  return icon;
};

export const ConfigurationsCloudAvatar = ConfigurationsCloudAvatarComponent;
