// @flow
import ImageApiManagement from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageApiManagement.png';
import ImageCentral from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageCentral.png';
import ImageAccess from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageAccess.png';
import ImageServiceAccount from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageServiceAccount.png';
import ImageServiceAccountDetails from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageServiceAccountDetails.png';
import ImageClientId from './ConfigurationsCloudStepperAxwayMarkdownStep3ImageClientId.png';

export default `
1. Log into your admin account on [https://platform.axway.com](https://platform.axway.com).


2. Go to \`API Management\`

    ![Axway API Management](${ImageApiManagement})


3. Then go to \`Central\`

    ![Axway Central](${ImageCentral})


4. On the left hand side, choose \`Access\`

    ![Axway Access](${ImageAccess})


5. Then in \`Service Accounts\` (1), click on the \`+ Service account\` (2) button

    ![Axway Service Accounts](${ImageServiceAccount})


6. Add a name to your service account (1), for example \`DataTheorem Security Audit\`, then copy/paste your public key into the second field (2). Lastly, click on the \`Save\` button (3).

    ![Axway Service Account Details](${ImageServiceAccountDetails})


7. Copy the \`Client ID\`

    ![Axway Client ID](${ImageClientId})


`;
