// @flow
import React, { useState, useMemo, useEffect } from 'react';
import memoize from 'memoizee';
import format from 'date-fns/format';
import {
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { palette } from '@dt/theme';
import { Analytics, dataCreators } from '@dt/analytics';
import { DiscoveredVia } from '@dt/horizon-api';
import Markdown from '../Markdown';
import openapi_definitions from '@dt/graphql-support/horizon/openapi_definitions';
import CloudDownload from '@material-ui/icons/CloudDownload';
import AlertIcon from '@material-ui/icons/Warning';
import { OpenApiDefinitionsUpload } from './OpenApiDefinitionsUpload';

import type {
  OpenApiDefinitionsListQuery,
  OpenApiDefinitionsListQueryVariables,
} from '@dt/graphql-support/types/OpenApiDefinitionsListQuery';

const parseDocument = memoize((original_document: string): ?string => {
  let previewContent;
  try {
    // Original_document is a stringified JSON twice
    // We need to parse first, which will give us the original object
    // so then we stringify ourselves to introduce break lines
    // if it's yaml format, parse will fail
    previewContent = JSON.stringify(
      JSON.parse(JSON.parse(original_document)),
      null,
      2,
    );
  } catch (e) {
    // show original, as it is
    previewContent = original_document;
  }

  return previewContent;
});

const generateUrlForPreview = memoize((fileContent: string): string => {
  if (!fileContent) return '';

  const blob = new Blob([fileContent], { type: 'application/json' });
  return URL.createObjectURL(blob);
});

type Props = {|
  +onOpenApiDefinitionUploadClose: () => void,
|};

const OpenApiDefinitionsListComponent = function OpenApiDefinitionsList({
  onOpenApiDefinitionUploadClose,
}: Props) {
  const { data, error, fetchMore } = useQuery<
    OpenApiDefinitionsListQuery,
    OpenApiDefinitionsListQueryVariables,
  >(openapi_definitions.list);

  useEffect(() => {
    fetchMore && fetchMore();
  }, [fetchMore]);

  const [previewId, setPreviewId] = useState<?string>(null);

  const previewContent = useMemo(() => {
    if (!previewId) return 'Not found any definition matching this id';
    const previewDefinition = data?.openapi_definitions_list.openapi_definitions.filter(
      ({ id }) => id === previewId,
    )[0];
    // Invalid id ?
    if (!previewDefinition) return 'Not found any definition matching this id';
    return parseDocument(previewDefinition.original_document);
  }, [previewId, data]);

  const items = useMemo(
    () =>
      (
        data?.openapi_definitions_list.openapi_definitions.filter(
          ({ discovered_via }) =>
            discovered_via === DiscoveredVia.MANUAL_IMPORT,
        ) || []
      ).filter(Boolean),
    [data],
  );

  const downloadUrl = previewContent
    ? generateUrlForPreview(previewContent)
    : false;

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <React.Fragment>
      <p>Import an API Definition Document</p>
      <List>
        {items.length < 1 ? (
          <ListItem disabled>
            <ListItemIcon>
              <AlertIcon />
            </ListItemIcon>
            <ListItemText primary="No OpenAPI Definition found" />
          </ListItem>
        ) : (
          items.map(definition => (
            <ListItem
              button
              key={definition.id}
              onClick={() => setPreviewId(definition.id)}
            >
              <ListItemText
                primary={definition.title}
                secondary={`Added on ${format(
                  definition.date_created,
                  'MMM D, YYYY',
                )}`}
              />
            </ListItem>
          ))
        )}
      </List>

      <Typography>
        An OpenAPI definition is a document describing the various operations
        and the functionality implemented by an API. <br /> Data Theorem highly
        encourages the use of OpenAPI specification in either YAML or JSON
        format to describe APIs.
        <br />
        We also support the following specifications: Google Discovery Document,
        Mashery I/O Docs, RAML, Swagger, Swagger2 (YAML or JSON). <br />
        <br /> Note that RAML specifications will need to be contained in a
        single file and cannot refer to external entities with the !include tag.
      </Typography>

      <OpenApiDefinitionsUpload onClose={onOpenApiDefinitionUploadClose} />
      <Dialog
        aria-labelledby="dialog-api-document-preview"
        maxWidth="md"
        fullWidth
        open={!!previewId}
        onClose={() => setPreviewId(null)}
        scroll="paper"
      >
        <DialogTitle id="dialog-import-api-document">
          OpenAPI Definition Preview
        </DialogTitle>
        <DialogContent>
          {previewId && (
            <Analytics
              // Different previewId, different component
              key={previewId}
              trackOnMount={dataCreators.previewOpenAPIDefinition(previewId)}
            />
          )}
          <Markdown
            text={`\`\`\`\n${previewContent || 'Not found document'}\n\`\`\``}
          />
        </DialogContent>
        <DialogActions>
          <a
            href={downloadUrl}
            download={`dt-openapidefinition-${
              !previewId ? 'openapi' : previewId
            }.json`}
          >
            <Button aria-label="Download" variant="outlined">
              <CloudDownload
                style={{ color: palette.brand40, marginRight: 10 }}
              />{' '}
              Download
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const OpenApiDefinitionsList = OpenApiDefinitionsListComponent;
