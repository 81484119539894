//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation NetworkServicesCreateMutation($body: NetworkServicesCreateParams!) {
    network_services_create(body: $body) {
      id
      port
      url
      status
      application_layer_protocol
      is_tls_encrypted
      hosted_on
      date_no_longer_accessible
      asset_type_name
      asset_type_icon_url
      discovered_via
      date_created
    }
  }
`;

const get = gql`
  query NetworkServiceDetails($id: ID) {
    network_service_details(id: $id) {
      cloud_resources {
        id
        name
        resource_type
        asset_type_name
        asset_type_icon_url
      }
      restful_apis {
        id
        base_url
        asset_type_name
        asset_type_icon_url
      }
      network_services {
        id
        url
        hosted_on
        date_created
        status
        date_no_longer_accessible
        asset_type_icon_url
        asset_type_name
        domain_name {
          id
          name
        }
        discovered_via
      }
      web_applications {
        id
        base_url
        asset_type_name
        asset_type_icon_url
      }
      policy_violations {
        id
        date_resolved
        date_created
        exception_type
        exception_date_created
        status
        violated_policy_rule {
          id
          relevance
          policy_rule_type {
            id
            title
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
        affected_api_operation {
          id
          http_method
          path
          restful_api {
            id
            base_url
          }
        }
        affected_cloud_resource {
          id
          asset_type_name
          url
        }
        affected_network_service {
          id
          domain_name {
            id
            name
          }
        }
        affected_web_application {
          id
          base_url
        }
      }
      certificate_chains {
        id
        security_properties {
          enum
          value
          relevance
        }
        certificates {
          id
          issuer_as_rfc4514_string
          date_created
          domain_names_in_cn
          public_key_algorithm
          signature_hash_algorithm
          public_key_size
          not_valid_after
        }
      }
      tls_scan_results {
        tls_scan_attributes {
          enum
          value
          policy_violation: opened_policy_violation {
            violated_policy_rule {
              id
              relevance
            }
          }
        }
      }
    }
  }
`;

export default {
  create,
  get,
};
