//@flow
import React, { useEffect } from 'react';

import { IpRangesHeader } from './../ip_ranges/IpRangesHeader';
import { IpRangesFooter } from './../ip_ranges/IpRangesFooter';
import { IpRangesTable } from './../ip_ranges/IpRangesTable';
import { IpRangesScannerForm } from './../ip_ranges/IpRangesScannerForm';
import { IpRangesScanDetails } from './../ip_ranges/IpRangesScanDetails';
import { IpRangesMessage } from './../ip_ranges/IpRangesMessage';
import { useIpRanges } from './../ip_ranges/use_ip_ranges';

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add ip ranges.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperIpRangesComponent = function ConfigurationsCloudStepperIpRanges() {
  const [
    createIpAddressRange,
    {
      ip_range_scan,
      scanIpAddressRange,
      scanIpAddressRangeError,
      scanIpAddressRangeLoading,
      listIpAddressRangeData,
      listIpAddressRangeError,
      listIpAddressRangeFetchMore,
      listIpAddressRangeLoading,
      listIpAddressRangePageNumber,
      listIpAddressRangeRefetch,
      createIpAddressRangeData,
      createIpAddressRangeLoading,
      createIpAddressRangeError,
      patchIpAddressRange,
      patchIpAddressRangeError,
    },
  ] = useIpRanges();

  // Fetch all ip address ranges.
  useEffect(() => {
    listIpAddressRangeFetchMore && listIpAddressRangeFetchMore();
  }, [listIpAddressRangeFetchMore]);

  return (
    <div style={{ minWidth: 415 }}>
      <IpRangesHeader />
      <IpRangesMessage
        ip_range_scan={ip_range_scan}
        createIpAddressRangeData={createIpAddressRangeData}
        scanIpAddressRangeError={scanIpAddressRangeError}
        createIpAddressRangeError={createIpAddressRangeError}
        patchIpAddressRangeError={patchIpAddressRangeError}
        listIpAddressRangeError={listIpAddressRangeError}
      />
      <IpRangesScannerForm
        isLoading={scanIpAddressRangeLoading}
        onSubmit={data => {
          scanIpAddressRange({
            variables: {
              body: data,
            },
          });
        }}
      />
      <IpRangesFooter />
      {!scanIpAddressRangeLoading &&
        !scanIpAddressRangeError &&
        ip_range_scan &&
        !ip_range_scan.ip_range_id && (
          <IpRangesScanDetails
            showResultsAsCards
            canAddIpAddressRange={
              !createIpAddressRangeLoading && !createIpAddressRangeError
            }
            ip_range_scan={ip_range_scan}
            onAddScanResults={createIpAddressRange}
          />
        )}

      <IpRangesTable
        isLoading={
          !listIpAddressRangeData ||
          (listIpAddressRangeLoading && listIpAddressRangePageNumber === 0)
        }
        ip_ranges={
          listIpAddressRangeData
            ? listIpAddressRangeData.ip_ranges_list.ip_ranges
            : []
        }
        onChangeContinuousDiscoveryEnabled={ip_range => {
          (async function() {
            await patchIpAddressRange({
              variables: {
                id: ip_range.id,
                body: {
                  has_continuous_discovery_enabled: !ip_range.has_continuous_discovery_enabled,
                },
              },
            });
            await listIpAddressRangeRefetch();
          })();
        }}
      />
    </div>
  );
};

export const ConfigurationsCloudStepperIpRanges = ConfigurationsCloudStepperIpRangesComponent;
