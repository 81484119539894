// @flow
import React, { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { palette } from '@dt/theme';
import { Dropzone } from '../Dropzone';
import { OpenApiDefinitionsUploadMessage } from './OpenApiDefinitionsUploadMessage';
import { useOpenApiDefinitionsUpload } from './use_open_api_definitions_upload';

type Props = {|
  +onClose: () => void,
|};

const OpenApiDefinitionsUploadComponent = function OpenApiDefinitionsUpload({
  onClose,
}: Props) {
  const [
    uploadOpenApiDefinition,
    {
      data: uploadOpenApiDefinitionData,
      loading: uploadOpenApiDefinitionLoading,
      error: uploadOpenApiDefinitionError,
    },
  ] = useOpenApiDefinitionsUpload();

  // Form state.
  const [apiUrl, setApiUrl] = useState<string>('');
  const [curlCommand, setCurlCommand] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [file, setFile] = useState<?File>(null);
  // An additional `isSubmitted` state is needed to allow the user to upload multiple files but disable submission after
  // upload. If not used `uploadOpenApiDefinitionData` is always defined and can't be "reset", please update this if
  // mutation state can be reset in the future.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // Event handlers.
  const handleOnChangeApiUrl = (
    event: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    setApiUrl(value);
  };
  const handleOnChangeCurlCommand = (
    event: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    setCurlCommand(value);
  };
  const handleOnChangeComments = (
    event: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    setComments(value);
  };
  const handleOnSubmit = async () => {
    setIsSubmitted(true);
    await uploadOpenApiDefinition({ file, apiUrl, curlCommand, comments });
    setFile(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          To enable authenticated API scanning, submit CURL commands with the
          required API tokens or credentials.
        </Typography>
        <Typography>
          See{' '}
          <a href="https://datatheorem.atlassian.net/servicedesk/customer/portal/1/article/721584141?src=-1681577665">
            this article
          </a>{' '}
          for more details about authenticated API scanning.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="apiURL"
          fullWidth
          label="API URL"
          placeholder="API URL"
          variant="outlined"
          value={apiUrl}
          onChange={handleOnChangeApiUrl}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="CURLCommand"
          fullWidth
          label="CURL command to authenticate"
          placeholder="CURL command to authenticate"
          multiline
          rows={5}
          variant="outlined"
          value={curlCommand}
          onChange={handleOnChangeCurlCommand}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="comments"
          fullWidth
          label="Comments"
          placeholder="Comments"
          multiline
          rows={5}
          variant="outlined"
          value={comments}
          onChange={handleOnChangeComments}
        />
      </Grid>

      <Grid item xs={12}>
        <Dropzone
          ariaLabel="OpenApi Definition Dropzone"
          onDropFile={(file: File) => {
            setIsSubmitted(false);
            setFile(file);
          }}
          dragActiveText="Drop your OpenAPI definition file to upload it"
        >
          <OpenApiDefinitionsUploadMessage
            style={{
              height: 200,
              borderColor: palette.gray30,
              borderStyle: 'dotted',
              borderWidth: '2px',
              margin: 'auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            isSubmitted={isSubmitted}
            file={file}
            uploadOpenApiDefinitionData={uploadOpenApiDefinitionData}
            uploadOpenApiDefinitionLoading={uploadOpenApiDefinitionLoading}
            uploadOpenApiDefinitionError={uploadOpenApiDefinitionError}
          />
        </Dropzone>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              aria-label="Submit"
              color="primary"
              variant="contained"
              disabled={isSubmitted || !file}
              onClick={() => {
                handleOnSubmit();
              }}
            >
              {uploadOpenApiDefinitionLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                'Submit'
              )}
            </Button>
          </Grid>

          <Grid item>
            <Button aria-label="Close Dialog" onClick={onClose}>
              Close dialog
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const OpenApiDefinitionsUpload = OpenApiDefinitionsUploadComponent;
