// @flow
import ImageOrganization from './ConfigurationsCloudStepperAxwayMarkdownStep4ImageOrganization.png';
import ImageOrganizationId from './ConfigurationsCloudStepperAxwayMarkdownStep4ImageOrganizationId.png';

export default `
1. Click on your profile button on the upper right hand side of the web portal (1) and then click on \`Organization\` (2)

    ![Axway Organization](${ImageOrganization})


2. Copy your \`Organization ID\`

    ![Axway Organization ID](${ImageOrganizationId})


`;
