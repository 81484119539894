//@flow
import React from 'react';
import { NetworkServicesManualAdd } from './../NetworkServicesManualAdd';

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add network services.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperNetworkServicesComponent = function ConfigurationsCloudStepperNetworkServices() {
  return <NetworkServicesManualAdd />;
};

export const ConfigurationsCloudStepperNetworkServices = ConfigurationsCloudStepperNetworkServicesComponent;
