//@flow
import React, { memo } from 'react';
import { Message } from '../Message';

import type { ApolloError } from '@apollo/client';
import type { IpRangesCreateMutation } from '@dt/graphql-support/types/IpRangesCreateMutation';
import type { IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans } from '@dt/graphql-support/types/IpRangeScansCreateMutation';

type Props = {|
  +ip_range_scan: void | null | IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans,
  +createIpAddressRangeData: void | IpRangesCreateMutation,
  +listIpAddressRangeError: void | ApolloError,
  +scanIpAddressRangeError: void | ApolloError,
  +createIpAddressRangeError: void | ApolloError,
  +patchIpAddressRangeError: void | ApolloError,
|};

/*
 * User message workflow component.
 *
 * Used to render messages to the user when certain conditions are met for the ip ranges component.
 */
const IpRangesMessageComponent = function IpRangesMessage({
  ip_range_scan,
  createIpAddressRangeData,
  listIpAddressRangeError,
  scanIpAddressRangeError,
  createIpAddressRangeError,
  patchIpAddressRangeError,
}: Props) {
  if (listIpAddressRangeError) {
    return (
      <Message
        m={0}
        variant="error"
        message="Unable to list out your IP Address Ranges at this time. Please try again later!"
      />
    );
  }
  if (scanIpAddressRangeError) {
    return (
      <Message
        m={0}
        variant="error"
        message={`Unable to scan IP Address Range at this time. Please try again later! ${scanIpAddressRangeError.message}`}
      />
    );
  } else if (ip_range_scan && ip_range_scan.ip_range_id) {
    return (
      <Message
        m={0}
        variant="info"
        message="IP Address Range is already being scanned. See IP Address Ranges."
      />
    );
  }
  if (createIpAddressRangeError) {
    return (
      <Message
        m={0}
        variant="error"
        message="Unable to add IP Address Range at this time. Please try again later!"
      />
    );
  } else if (createIpAddressRangeData) {
    return (
      <Message
        m={0}
        variant="success"
        message="IP Addresses Added Successfully!"
      />
    );
  }
  if (patchIpAddressRangeError) {
    return (
      <Message
        m={0}
        variant="error"
        message="Unable to update IP Address Range at this time. Please try again later!"
      />
    );
  }
  return null;
};

export const IpRangesMessage = memo<Props>(IpRangesMessageComponent);
