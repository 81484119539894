//@flow
import React from 'react';
import { ConfigurationsCloudNameIdentifier } from './ConfigurationsCloudNameIdentifier';
import type { ConfigurationsCloudVariant } from '../types';

type Props = {|
  +variant: ConfigurationsCloudVariant,
|};

/*
 * Success message shown to the user when they finish onboarding a configuration.
 *
 * @param variant - Variant to render, used to display the name of the configuration.
 */
const ConfigurationsCloudStepperSuccessMessageComponent = function ConfigurationsCloudStepperSuccessMessage({
  variant,
}: Props) {
  return (
    <>
      <ConfigurationsCloudNameIdentifier variant={variant} /> environment access
      successfully added! The environment will be scanned immediately. Please
      refresh the page in about 10 minutes to see your results.
    </>
  );
};

export const ConfigurationsCloudStepperSuccessMessage = ConfigurationsCloudStepperSuccessMessageComponent;
