// @flow
export default `
1. Generate the private key:

    \`openssl genpkey -algorithm RSA -out private_key.pem -pkeyopt rsa_keygen_bits:2048\`


2. Generate the public key:

    \`openssl rsa -pubout -in private_key.pem -out public_key.pem\`

`;
