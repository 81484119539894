//@flow
import React from 'react';
import { Backdrop, Grid, Button, Typography, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const IpRangesContinuousDiscoveryModalButtons = {
  ENABLE: 'ENABLE',
  CANCEL: 'CANCEL',
};

type Props = {|
  +ip_range: string,
  +open: boolean,
  +onModalClosed: ({|
    +button: $Keys<typeof IpRangesContinuousDiscoveryModalButtons>,
  |}) => void,
|};

const useStyles = makeStyles({
  root: {
    color: 'black',
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    padding: '15px 20px',
    outline: 'none',
    background: 'white',
    minWidth: '60%',
    maxWidth: 600,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controls: {
    marginTop: 20,
    marginBottom: 20,
  },
});

/*
 * User is shown a dialog to confirm whether they want continuous discovery scanning for the specified ip range.
 *
 * @param ip_range - The ip range to potentially enable continuous discovery for
 * @param open - Whether or not the modal is open or not
 * @param onModalClosed - Triggered event when the user closes the modal
 *
 * @example
 *     const [isModalOpen, setIsModalOpen] = useState(false);
 *
 *     return (
 *       <IpRangesContinuousDiscoveryModal ip_range={ip_range} open={isModalOpen} onModalClosed={() => setIsModalOpen(false)} />
 *     );
 */
const IpRangesContinuousDiscoveryModal = ({
  ip_range,
  open,
  onModalClosed,
}: Props) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.root}>
        <Typography variant="h5" component="h5" gutterBottom>
          Continuous Discovery
        </Typography>

        <Typography variant="body1" gutterBottom>
          Enabling continuous discovery for the IP Address Range{' '}
          <b>{ip_range}</b> will cause Data Theorem to discover this IP Address
          Range daily. Any results found within the IP address range will be
          automatically added as newly-discovered assets in your inventory.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You should only enable continuous discovery for IP address ranges{' '}
          <b>that you fully own</b>.
        </Typography>

        <Grid
          container
          justify="space-around"
          alignItems="center"
          className={classes.controls}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                onModalClosed({
                  button: IpRangesContinuousDiscoveryModalButtons.ENABLE,
                })
              }
            >
              Enable
            </Button>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                onModalClosed({
                  button: IpRangesContinuousDiscoveryModalButtons.CANCEL,
                })
              }
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default React.memo<Props>(IpRangesContinuousDiscoveryModal);
