//@flow
import gql from 'graphql-tag';

export const list = gql`
  query CloudAuthenticatorsList {
    cloud_authenticators_list {
      pagination_information {
        next_cursor
      }
      cloud_authenticators {
        authenticator_category
        authenticator_type
        cloud_account_id
        customer_supplied_name
        date_created
        id
        status
      }
    }
  }
`;

export const update = gql`
  mutation UpdateCloudAuthenticator(
    $id: ID!
    $body: CloudAuthenticatorUpdateParams!
  ) {
    cloud_authenticator_update(id: $id, body: $body) {
      authenticator_category
      authenticator_type
      cloud_account_id
      customer_supplied_name
      date_created
      id
      status
    }
  }
`;

export default {
  list,
  update,
};
