//@flow
import {
  type ApolloQueryResult,
  type FetchMoreQueryOptions,
  type FetchMoreOptions,
} from '@apollo/client';
import deepmerge from 'deepmerge';

type OriginalFetchMore<TData, TVariables> = (fetchMoreOptions: {
  ...FetchMoreQueryOptions<TVariables>,
  ...FetchMoreOptions<TData, TVariables>,
}) => Promise<ApolloQueryResult<?TData>>;

export type NewFetchMore<TData, TVariables> = (
  variables?: ?TVariables,
) => Promise<ApolloQueryResult<?TData>>;

const duplicateMessage = `Do not call fetchMore unless it has changed. Use useEffect is one
          way to make sure that doesn't happen:
            useEffect(()=>{
              fetchMore && fetchMore();
            },[fetchMore])
          A new fetchMore function will be returned every time there is a
          next_cursor.`;

export default function makeFetchMore<
  TData,
  TVariables: { cursor?: ?string, ... } = { cursor?: ?string },
>(
  // fetchMore has a `previousData` arg as its first parameter so why do we
  // need to pass `data` here? Well, there's a bug with this arg and I couldn't
  // figure out why it was happening.
  // https://github.com/apollographql/apollo-client/issues/5703
  data: ?TData,
  originalFetchMore: OriginalFetchMore<TData, TVariables>,
  cursor: string,
): NewFetchMore<TData, TVariables> {
  let hasBeenCalled = false;
  return variables => {
    if (hasBeenCalled) {
      console.warn(duplicateMessage);
      return Promise.reject(new Error(duplicateMessage));
    }
    hasBeenCalled = true;
    return originalFetchMore({
      variables: {
        cursor,
        ...variables,
      },
      // Do not use the first argument! See note in comment above.
      updateQuery: (_: TData, { fetchMoreResult }): ?TData => {
        if (!fetchMoreResult) {
          console.warn(`fetchMore response was falsey`);
          return data;
        }
        if (typeof fetchMoreResult !== 'object') {
          console.warn(`fetchMore response was not an object.`);
          return data;
        }
        if (!data || typeof data !== 'object') {
          console.warn(`fetchMore called with previous data being null or not
            an object. Did you call it too early?`);
          return data;
        }

        return deepmerge<TData, TData, TData>(
          data,
          sevenhellArrayHack(fetchMoreResult, data),
          { arrayMerge: dedupe },
        );
      },
    });
  };
}

//
// Our APIs have a weakness due to how Google API's ProtoRPC-to-json conversion
// works. When an empty array is supposed to be returned for a field, instead
// the key is undefined. This isn't a big deal in most places (GraphQL will
// define the field and set it to null), but in pagination it is a problem
// because when we merge the next page into the previous, `deepmerge` sees the
// null value as distinct from undefined, and overrides the previous page's
// array with the new one.
//
// So to solve this, we examine the previous page and if it has an array on a
// key, and the incoming page has the key set to null, we set it to an empty
// array instead. We do this only on an object somewhere in the page tree that
// has the pagination_information key
function sevenhellArrayHack<T>(page: T, pageToCompare: mixed): T {
  if (
    !page ||
    typeof page !== 'object' ||
    !pageToCompare ||
    typeof pageToCompare !== 'object'
  ) {
    return page;
  }

  const { pagination_information } = page;
  if (pagination_information) {
    return nullPropsToEmptyArray(page, pageToCompare);
  } else {
    let result = { ...page };
    for (const key in page) {
      result[key] = sevenhellArrayHack(page[key], pageToCompare[key]);
    }
    return result;
  }
}

function nullPropsToEmptyArray<T>(target: T, compare: mixed): T {
  if (
    !target ||
    typeof target !== 'object' ||
    !compare ||
    typeof compare !== 'object'
  ) {
    return target;
  }
  const result = { ...target };
  Object.keys(compare).forEach(key => {
    if (Array.isArray(compare[key]) && target[key] === null) {
      result[key] = [];
    }
  });

  return result;
}

function dedupe(target, source, options) {
  const destination = target.slice();

  for (const item of source) {
    if (
      item &&
      typeof item === 'object' &&
      item.id &&
      typeof item.id === 'string' &&
      destination.find(
        originalItem =>
          originalItem &&
          typeof originalItem === 'object' &&
          originalItem.id === item.id,
      )
    ) {
      continue;
    } else {
      destination.push(options.cloneUnlessOtherwiseSpecified(item, options));
    }
  }
  return destination;
}
