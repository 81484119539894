// @flow

import ImageSubmitCredentialApplicationId from './ConfigurationsCloudStepperAzureMarkdownStep3ImageSubmitCredentialApplicationId.png';
import ImageSubmitCredentialApplicationSecrets from './ConfigurationsCloudStepperAzureMarkdownStep3ImageSubmitCredentialApplicationSecrets.png';

export default `
After creating the read-only role we need to copy over the credentials so Data Theorem can access your environment. Once done you should have the following:

- **Application ID**
- **Directory ID**
- **Secret key** (DO NOT share with anyone else)

**Obtaining your Application ID, Directory ID and secret key**
1. Navigate to the **Azure Active Directory** page
2. Select **App Registrations**
3. Search for and select the app registration **Data Theorem** (this was created in an earlier step)
    - If you can't find it on the list, make sure you are viewing **All Apps** and *not* just **Owned Apps**.
4. In **Overview**:
    - Copy out the **Application (client) ID**
    - Copy out the **Directory (tenant) ID**

   You should have something like the following. This is an example only!

![Azure Application Registration](${ImageSubmitCredentialApplicationId})

5. Click on **Certificates and secrets**
6. Click on **New client secret**
    - **Description**: "apikey"
    - **Expires**: "Never"

   You should have something like the following. This is an example only!

![Azure Application Secrets](${ImageSubmitCredentialApplicationSecrets})

7. Click **Add**
8. Copy out the generated client secret, under the **Value** column.
`;
