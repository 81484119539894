//@flow
export default function defaultNextCursorSelector(data: mixed): string | null {
  if (!data || typeof data !== 'object' || Object.keys(data).length < 1) {
    throw new Error(`useQuery received a response with no data, so it couldn't
      find the next cursor. If you are not expecting any pagination to occur
      with this query, use Apollo's useQuery hook instead of this one. If there
      is still pagination but its performed in an unconventional way, provide a
      custom cursorSelector function as an option.`);
  }

  const keys = Object.keys(data);
  const next_cursors = [];
  for (const key of keys) {
    const top_level_object = data[key];
    if (!top_level_object || typeof top_level_object !== 'object') {
      continue;
    }

    const pagination_information = top_level_object.pagination_information;
    if (!pagination_information || typeof pagination_information !== 'object') {
      continue;
    }

    const next_cursor = pagination_information.next_cursor;
    if (!next_cursor || typeof next_cursor !== 'string') {
      next_cursors.push(null);
    } else {
      next_cursors.push(next_cursor);
    }
  }

  if (next_cursors.length === 0) {
    throw new Error(`useQuery received a response with no top-level object
      containing pagination_information. If you are not expecting any
      pagination to occur with this query, use Apollo's useQuery hook instead
      of this one. If there is still pagination but its performed in an
      unconventional way, provide a custom cursorSelector function as an
      option.`);
  } else if (next_cursors.length > 1) {
    throw new Error(`useQuery received a response with multiple
      pagination_information responses. This probably means you have included
      multiple paginatable fields in your query. That's not going to work out
      well because if you think about it, how would you get the next page of
      one of those fields but not the other? They are part of the same query so
      eventually there's going to be some sort of duplication. You should make
      these separate useQuery instances or consider using useMultiplexQuery.`);
  }

  return next_cursors[0];
}
