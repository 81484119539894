//@flow
import createSagaMiddleware, {
  type SagaMiddleware,
  type Task,
} from 'redux-saga';
import { Raven } from '@dt/global';

import containerSaga from './redux/containers/sagas';

export function getMiddleware(): SagaMiddleware<{||}> {
  return createSagaMiddleware({
    onError: (e, { sagaStack }) => {
      console.error(e, sagaStack);
      Raven.captureException(e, { extra: { sagaStack } });
    },
  });
}

export function runSagas(withMiddleware: SagaMiddleware<{||}>): Task<void> {
  return withMiddleware.run(containerSaga);
}
