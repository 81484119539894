// @flow
import { type Saga } from 'redux-saga';
import { spawn, take, call, put, race } from 'redux-saga/effects';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { getUserAccount } from '@dt/session';

import { watchForCreateSession } from '../user_sessions/sagas';
import {
  sessionCreate,
  sessionCreateSuccess,
  sessionCreateError,
} from '../user_sessions/actions';
import { temporarySessionStarted, sagasCreated } from './actions';
import { setTemporaryToken } from '@dt/horizon-api';

export default function* containerFlow(): Saga<void> {
  const { sessionValidated, temporarySessionToken } = yield race({
    sessionValidated: call(function*() {
      try {
        return yield* callPromise(getUserAccount);
      } catch (err) {
        return null;
      }
    }),
    temporarySessionToken: take(temporarySessionStarted.toString()),
  });

  if (sessionValidated) {
    // Spawn session create saga first
    yield spawn(watchForCreateSession);
    yield put(sessionCreate(sessionValidated.sessionId));
  } else if (temporarySessionToken) {
    yield call(setTemporaryToken, temporarySessionToken.payload);
  }

  yield put(sagasCreated());

  // TODO: We try to authenticate, but keep the train rolling if we fail.
  // This is because dev-horizon does not support authentication but
  // prod-horizon requires it. :/

  yield take(
    action =>
      action.type === sessionCreateSuccess.toString() ||
      action.type === sessionCreateError.toString(),
  );
}
