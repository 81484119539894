// @flow
import React, { memo, useState } from 'react';
import { Drawer, DialogTitle, DialogContent } from '@material-ui/core';
import { ConfigurationsCloudStepper } from './ConfigurationsCloudStepper';
import { ConfigurationsCloudNameIdentifier } from './ConfigurationsCloudNameIdentifier';
import type { ConfigurationsCloudVariant } from '../types';

import type { Node } from 'react';

type Props = {|
  +variant: ConfigurationsCloudVariant,
  +children?: Node,
|};

const ConfigurationsCloudAddAccessPickerComponent = function ConfigurationsCloudAddAccessPicker({
  variant,
  children,
}) {
  const [isModalOpen, setModalOpen] = useState();

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        tabIndex={0}
        onClick={() => setModalOpen(!isModalOpen)}
      >
        {children}
      </div>

      <Drawer
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          style: {
            width: '60%',
            minWidth: 200,
          },
        }}
        anchor="right"
        aria-labelledby={`dialog-onboarding-${variant}`}
      >
        <DialogTitle>
          <span id={`dialog-onboarding-${variant}`}>
            Set up Data Theorem{' '}
            <ConfigurationsCloudNameIdentifier variant={variant} /> Access
          </span>
        </DialogTitle>
        <DialogContent>
          <ConfigurationsCloudStepper
            variant={variant}
            onComplete={() => setModalOpen(false)}
          />
        </DialogContent>
      </Drawer>
    </>
  );
};

export const ConfigurationsCloudAddAccessPicker = memo<Props>(
  ConfigurationsCloudAddAccessPickerComponent,
);
