// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import { palette } from '@dt/theme';
import kong_authenticators from '@dt/graphql-support/horizon/kong_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperKongMarkdownStep1 from './ConfigurationsCloudStepperKongMarkdownStep1';
import configurationsCloudStepperKongMarkdownStep2 from './ConfigurationsCloudStepperKongMarkdownStep2';
import configurationsCloudStepperKongMarkdownStep3 from './ConfigurationsCloudStepperKongMarkdownStep3';

import type {
  KongAuthenticatorsCreateMutation,
  KongAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/KongAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

const KONG_CLOUD_DOMAIN_REGEX = /-*([\w]+)\.kong-cloud/;

/*
 * Generates a user token for the user's kong environment.
 */
const generateUserToken = () => {
  let cryptoObj = window.crypto || window.msCrypto;
  const abc = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  let token = '';
  const typedArray = new Uint8Array(32);
  cryptoObj.getRandomValues(typedArray);

  for (let i = 0; i < typedArray.length; i++) {
    token += abc[typedArray[i] % 36];
  }
  return token;
};
const sessionGeneratedUserToken = generateUserToken();

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Kong authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperKongComponent = function ConfigurationsCloudStepperKong({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [domain, setDomain] = useState('');
  const [userToken, setUserToken] = useState(sessionGeneratedUserToken);
  const [userTokenEditable, setUserTokenEditable] = useState(false);

  // Kong authenticator create mutation.
  const [
    createKongAuthenticator,
    {
      loading: createKongAuthenticatorLoading,
      error: createKongAuthenticatorError,
    },
  ] = useMutation<
    KongAuthenticatorsCreateMutation,
    KongAuthenticatorsCreateMutationVariables,
  >(kong_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeDomain = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value = (e.target: HTMLInputElement).value;

    setDomain(value);
  };
  const handleToggleUserTokenEditable = () => {
    if (userTokenEditable) {
      setUserTokenEditable(false);
      setUserToken(sessionGeneratedUserToken);
    } else {
      setUserTokenEditable(true);
    }
  };
  const handleOnChangeUserToken = e => {
    const value = e.target.value;

    setUserToken(value);
  };
  const handleOnSubmitForm = async () => {
    const subdomainGroups = KONG_CLOUD_DOMAIN_REGEX.exec(domain);
    const subdomain = subdomainGroups && subdomainGroups[1];
    if (subdomain) {
      const { data, errors } = await createKongAuthenticator({
        variables: {
          body: {
            kong_admin_subdomain: subdomain,
            kong_admin_token: userToken,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  // Form validation.
  const isValidDomainRequired = domain.length > 0;
  const isValidDomainRegex = KONG_CLOUD_DOMAIN_REGEX.exec(domain);
  const isValidUserTokenRequired = userToken.length > 0;

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0, auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Prerequisite</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperKongMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Create a custom Kong User</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown
                text={configurationsCloudStepperKongMarkdownStep2(
                  sessionGeneratedUserToken,
                )}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Assign Read-only permission to Custom User</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperKongMarkdownStep3} />
            </div>

            <div style={{ marginBottom: 16 }}>
              {createKongAuthenticatorError && (
                <Message
                  variant="error"
                  message={createKongAuthenticatorError.message}
                />
              )}

              <TextField
                id="Admin endpoint"
                label="Admin Endpoint"
                variant="outlined"
                error={!isValidDomainRequired || !isValidDomainRegex}
                helperText={
                  isValidDomainRequired
                    ? isValidDomainRegex
                      ? 'e.g. https://admin-abc.kong-cloud.com'
                      : 'Please include a valid kong cloud domain. e.g. https://admin-abc.kong-cloud.com'
                    : 'Please enter your admin kong cloud domain'
                }
                autoFocus
                fullWidth
                value={domain}
                onChange={handleOnChangeDomain}
                style={{ display: 'block', marginBottom: 12 }}
              />
              <TextField
                fullWidth
                variant={'outlined'}
                margin="dense"
                label="User Token"
                helperText={
                  <span>
                    {userTokenEditable
                      ? isValidUserTokenRequired
                        ? 'Override is only for advanced users! Do not use unless you have a known User Token used during User creation.'
                        : 'Please enter a valid and secure User Token or use pre-defined'
                      : 'Generated randomly based on your session. Only valid for this session.'}
                    <span
                      style={{ cursor: 'pointer', color: palette.brand20 }}
                      onClick={handleToggleUserTokenEditable}
                    >
                      {' '}
                      {userTokenEditable
                        ? 'Use Suggested User Token'
                        : 'Override'}
                    </span>
                  </span>
                }
                error={!isValidUserTokenRequired}
                onChange={handleOnChangeUserToken}
                value={userToken}
                disabled={!userTokenEditable}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              nextLabel={
                createKongAuthenticatorLoading
                  ? 'Testing...'
                  : createKongAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
              nextDisabled={
                !isValidDomainRequired ||
                !isValidDomainRegex ||
                !isValidUserTokenRequired ||
                createKongAuthenticatorLoading
              }
              nextIsLoading={createKongAuthenticatorLoading}
              prevDisabled={createKongAuthenticatorLoading}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={4}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.Kong}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperKong = ConfigurationsCloudStepperKongComponent;
