//@flow
import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

type Props = {|
  +isLoading: boolean,
  +onSubmit: ({ ip_range: string }) => void,
|};

/*
 * Allows the user to scan an ip address range.
 *
 * @param isLoading - Whether or not the scan is loading or not
 * @param onSubmit - Event triggered when the user submits an ip address range for scanning
 *
 * @example
 *     <IpRangesScannerForm isLoading={isIpRangeScanLoading} onSubmit={handleOnSubmit} />
 */
export const IpRangesScannerForm = ({ isLoading, onSubmit }: Props) => {
  const [scanIpRange, setScanIpRange] = useState<string>('');

  return (
    <Box pt={2} pb={2}>
      <form
        onSubmit={e => {
          // Prevent page submit.
          e.preventDefault();

          // Handle scan request.
          let formattedScanIpRange;
          if (scanIpRange.indexOf('/') <= -1) {
            formattedScanIpRange = `${scanIpRange}/32`;
          } else {
            formattedScanIpRange = `${scanIpRange}`;
          }
          setScanIpRange(formattedScanIpRange);
          onSubmit({
            ip_range: formattedScanIpRange,
          });
        }}
      >
        <Box display="flex" flexWrap="nowrap" alignItems="center" mb={1}>
          <TextField
            style={{ margin: 0 }}
            margin="dense"
            label="IP Address Range"
            id="IPAddressRange"
            variant="outlined"
            fullWidth
            autoFocus
            value={scanIpRange}
            onChange={e => setScanIpRange(e.target.value.trim())}
          />

          <Box ml={1}>
            <Button
              style={{ minWidth: 90 }}
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              disabled={!scanIpRange || isLoading}
              aria-label="Scan"
            >
              {isLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                'Scan'
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
