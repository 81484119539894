// @flow

export default `
After creating the custom role we need to create an account that can access your environment.

1. Login into your MuleSoft Account through the [AnypointPlatform portal](https://anypoint.mulesoft.com/home/)

2. Under the title __Management Center__ select __Access Management__

   __Note__: If you can’t find __Access Management__ then you might not have access to set this up.

3. Select __Users__ from the left side menu

4. Click the __Invite user__ button

   __Note__: If the __Invite user__ button isn’t available you have to enable non-SSO users. See [Enable non-SSO Users Documentation](https://support.mulesoft.com/s/article/Why-is-the-Invite-User-button-not-available-in-my-Anypoint-Platform).

5. In the dialog box for the __Email addresses__ field enter an email account that you have access to. Make sure to write down the __Account Username__ to use later

6. For the __Role__ field enter the custom role setup earlier

   - DataTheoremAnalyzer

7. Click __Send invitation__

8. Once invited you'll need to finish creating the account with the invitation sent. Make sure to write down the __Account Password__ to use later

9. Once the account is setup we will need the __Organization ID__ this can be found by navigating to the [AnypointPlatform portal](https://anypoint.mulesoft.com/home/)
Once on the portal you will be redirected to a url that looks like the following

   - https://anypoint.mulesoft.com/home/organizations/ __11453f13-52b8-4454-86e0-f92f8a6ae6a8__

10. Copy out the bolded portion after https://anypoint.mulesoft.com/home/organizations/ this is your __Organization ID__

11. Submit the created account credentials below

#### MuleSoft Docs:

- [How to know my Organization ID](https://support.mulesoft.com/s/article/How-to-know-my-Organization-ID-Org-ID-on-the-Anypoint-Platform)
`;
