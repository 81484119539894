//@flow
import React, { memo } from 'react';
import { Message } from '../Message';

import type { ApolloError } from '@apollo/client';
import type { OpenApiDefinitionsCreateMutation } from '@dt/graphql-support/types/OpenApiDefinitionsCreateMutation';

type Props = {|
  +style?: mixed,
  +isSubmitted: boolean,
  +file: ?File,
  +uploadOpenApiDefinitionData: void | OpenApiDefinitionsCreateMutation,
  +uploadOpenApiDefinitionLoading: boolean,
  +uploadOpenApiDefinitionError: void | ApolloError | {| +message: string |},
|};

/*
 * User message workflow component.
 *
 * Used to render messages to the user when certain conditions are met for the open api definitions upload component.
 */
const OpenApiDefinitionsUploadMessageComponent = function OpenApiDefinitionsUploadMessage({
  style,
  isSubmitted,
  file,
  uploadOpenApiDefinitionData,
  uploadOpenApiDefinitionLoading,
  uploadOpenApiDefinitionError,
}: Props) {
  if (uploadOpenApiDefinitionLoading) {
    return <Message variant="info" message="Uploading..." style={style} />;
  } else if (isSubmitted && uploadOpenApiDefinitionError) {
    return (
      <Message
        variant="error"
        message={uploadOpenApiDefinitionError.message}
        style={style}
      />
    );
  } else if (file) {
    return (
      <Message
        variant="info"
        message={`Selected ${file.name}. Submit to upload the file.`}
        style={style}
      />
    );
  } else if (uploadOpenApiDefinitionData) {
    return (
      <Message
        variant="success"
        message={`Upload successful! It may take a few moments for our servers to parse and scan your schema.\nTo upload another schema, please drop or click to select a file.`}
        style={style}
      />
    );
  } else {
    return (
      <Message
        variant="info"
        message="Please drop your API definition document here or click to select a file."
        style={style}
      />
    );
  }
};

export const OpenApiDefinitionsUploadMessage = memo<Props>(
  OpenApiDefinitionsUploadMessageComponent,
);
