// @flow
import { createAction } from 'redux-actions';

export const temporarySessionStarted = createAction<
  'temporary_session/started',
  [string],
  string,
>('temporary_session/started', temporary_token => temporary_token);

export const sagasCreated = createAction<'sagasCreated'>('sagasCreated');
