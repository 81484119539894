//@flow
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import type { IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results } from '@dt/graphql-support/types/IpRangeScansCreateMutation';

type Props = {|
  +showResultsAsCards?: boolean,
  +ip_address_scan_results: Array<IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results>,
  +allowSelection: boolean,
  +selectedIpRangeScanResults: { [string]: boolean, ... },
  +onIpRangeScanResultToggled: (
    ip_rang_scan_result: IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results,
    selected: boolean,
  ) => void,
|};

const useStyles = makeStyles({
  row: {
    padding: 20,
  },
  ip: {
    fontSize: 20,
  },
});

const ScanResultLabel = ({
  ip_address_scan_result,
}: {|
  +ip_address_scan_result: IpRangeScansCreateMutation_ip_range_scans_create_ip_range_scans_ip_address_scan_results,
|}) => (
  <Typography variant="h5" color="primary" gutterBottom={false}>
    {ip_address_scan_result.ip_address}
  </Typography>
);

/*
 * Shows the user ip address range scan results.
 * Results are listed as ip addresses found in a ip address range scan.
 *
 * @param showResultsAsCards - Whether or not to render the ip address range scan resullts as cards or a list
 * @param allowSelection - Allows the user to select ip address range scan results
 * @param ip_address_scan_results - Scan results provided by the backend
 * @param selectedIpRangeScanResults - Selected ip address range scan results
 * @param onIpRangeScanResultToggled - Triggered event when the user selects/toggles a ip address range scan result
 *
 * @example
 *     <IpRangeScanResults
 *       showResultsAsCards
 *       allowSelection
 *       ip_address_scan_results={ip_address_scan_results}
 *       selectedIpRangeScanResults={selectedIpRangeScanResults}
 *       onIpRangeScanResultToggled={handleOnIpRangesScanResultToggled} />
 */
const IpRangeScanResults = ({
  showResultsAsCards,
  allowSelection,
  ip_address_scan_results,
  selectedIpRangeScanResults,
  onIpRangeScanResultToggled,
}: Props) => {
  const classes = useStyles();

  return (
    <Paper elevation={showResultsAsCards ? 1 : 0}>
      {ip_address_scan_results.map((result, i) => (
        <div key={result.ip_address}>
          {i !== 0 && <Divider />}
          <div className={classes.row}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedIpRangeScanResults[result.ip_address]}
                    disabled={!allowSelection}
                    onChange={e =>
                      onIpRangeScanResultToggled(result, e.target.checked)
                    }
                    value={result.ip_address}
                    color="primary"
                  />
                }
                label={<ScanResultLabel ip_address_scan_result={result} />}
                classes={{ label: classes.ip }}
              />
            </FormControl>
            {result.online_network_service_urls.map(url => (
              <Typography variant="body2" key={url}>
                {url}
              </Typography>
            ))}
          </div>
        </div>
      ))}
    </Paper>
  );
};

export default IpRangeScanResults;
