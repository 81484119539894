// @flow
import React, { memo, useState } from 'react';
import ConfigurableLHSMenuHeader from './ConfigurableLHSMenuHeader';
import {
  Drawer,
  NotificationsDrawer,
  ToolkitIcon,
} from '@dt/material-components';
import { makeStyles } from '@material-ui/styles';
import ConfigurableLHSMenuRoutes from './ConfigurableLHSMenuRoutes';
import ConfigurableLSHMenuChevron from './ConfigurableLHSMenuChevron';
import ConfigurableLHSMenuDevSecOps from './ConfigurableLHSMenuDevSecOps';
import ConfigurableLHSMenuProductSwitcher from './ConfigurableLHSMenuProductSwitcher';
import ConfigurableLHSMenuSettings from './ConfigurableLHSMenuSettings';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GavelIcon from '@material-ui/icons/Gavel';
import WarningIcon from '@material-ui/icons/Warning';
import ProtectIcon from '@material-ui/icons/Security';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

export type DTProductIconTypes =
  | typeof WebSecureIcon
  | typeof APISecureIcon
  | typeof CloudSecureIcon
  | typeof MobileSecureIcon
  | typeof DTIcon; // cross-product

export type DTTopLevelIconTypes =
  | typeof DashboardIcon
  | typeof ToolkitIcon
  | typeof GroupWorkIcon
  | typeof GavelIcon
  | typeof WarningIcon
  | typeof ProtectIcon
  | typeof SmartphoneIcon;

export type LeftMenuSubLevelEntry = {|
  to: string,
  name: string,
  exact?: boolean,

  // Set to true if this menu's link redirects to a different
  // application within Obol e.g. link to API Secure from
  // Cloud Secure.
  redirect?: boolean,
|};

export type LeftMenuTopLevelEntry = {|
  icon: DTTopLevelIconTypes,
  name: string,
  to?: string | null, // Optional, if clickable
  exact?: boolean,
  subEntries?: $ReadOnlyArray<LeftMenuSubLevelEntry>,

  // Set to true if this menu's link redirects to a different
  // application within Obol e.g. link to API Secure from
  // Cloud Secure.
  redirect?: boolean,
|};

export type DTProductConfig = {|
  to: string,
  name: string,
  icon: DTProductIconTypes,
|};

export type LeftMenuConfiguration = {|
  product: DTProductConfig,
  entries?: $ReadOnlyArray<LeftMenuTopLevelEntry>,
|};

let useStyle = makeStyles({
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
  },
});

type Props = {|
  configuration: LeftMenuConfiguration,
|};

function ConfigurableLHSMenuPage({ configuration }: Props) {
  const css = useStyle();
  const [navDrawerOpen, setNavDrawerOpen] = useState<boolean>(true);

  const { product, entries } = configuration;
  const isUsingMobileRouting = product.icon === MobileSecureIcon; // Mobile Product

  const dtProducts: $ReadOnlyArray<DTProductConfig> = [
    {
      to: '/dashboard',
      name: 'Dashboard',
      icon: DTIcon,
    },

    {
      to: '/api',
      name: 'API Secure',
      icon: APISecureIcon,
    },

    {
      to: '/cloud',
      name: 'Cloud Secure',
      icon: CloudSecureIcon,
    },

    {
      to: '/mobile/issues/priority',
      name: 'Mobile Secure',
      icon: MobileSecureIcon,
    },

    {
      to: '/web',
      name: 'Web Secure',
      icon: WebSecureIcon,
    },

    {
      to: '/openscan',
      name: 'Supply Chain Security',
      icon: BrandIconSupplyChainSecurity,
    },
  ];

  const showHeader =
    product.to.indexOf('/management/products') !== -1 ||
    product.to.indexOf('/dashboard') !== -1;

  return (
    <>
      <Drawer open={navDrawerOpen}>
        <nav className={css.drawerContent}>
          {/* Header section of LHS Menu */}
          {showHeader && (
            <ConfigurableLHSMenuHeader
              icon={product.icon}
              name={product.name}
              to={product.to}
            />
          )}

          {/* Routes */}
          <ConfigurableLHSMenuRoutes
            product={product}
            menuItems={entries}
            drawerOpen={navDrawerOpen}
            isUsingMobileRouting={isUsingMobileRouting}
            showHeader={showHeader}
          />

          {/* Chevron and other items below this pushed to bottom of page */}
          <ConfigurableLSHMenuChevron
            navDrawerOpen={navDrawerOpen}
            setNavDrawerOpen={setNavDrawerOpen}
          />

          {/* Notifications drawer */}
          <NotificationsDrawer isSidebarExpanded={navDrawerOpen} />

          {/* Product switcher */}
          <ConfigurableLHSMenuProductSwitcher products={dtProducts} />

          {/* DevSecOps */}
          <ConfigurableLHSMenuDevSecOps product={product} />

          {/* Settings */}
          <ConfigurableLHSMenuSettings />
        </nav>
      </Drawer>
    </>
  );
}

export default memo<Props>(ConfigurableLHSMenuPage);
