// @flow
type OpenAPIParseResult = OpenAPIParseSuccess | OpenAPIParseFailure;

type OpenAPIParseSuccess = string;

type OpenAPIParseFailure = {|
  error: string,
|};

export async function parseOpenAPIDefinitionFile(
  file: File,
): Promise<OpenAPIParseResult> {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = () => {
      if (!reader.result) {
        // If it's falsey, it's either an empty string (blank file), or null
        // because the reader hasn't finished reading or was unsuccessful. See:
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result
        return {
          error: 'Failed to parse schema. Is this a valid JSON file?',
        };
      } else if (typeof reader.result !== 'string') {
        // This should only ever happen if someone changes the code to no
        // longer use `reader.readAsText`. But we need to handle it to refine
        // the type for Flow.
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result
        return {
          error: 'Internal application error',
        };
      }

      resolve(reader.result);
    };

    reader.readAsText(file);
  });
}
