//@flow
import type { AccountInfo } from '@dt/user-api/account_info';
import type { User } from '@dt/user-api/users';
import config from '@dt/config';
import { getGateSession } from './gate';

const sevenhellApiVersion = '/_ah/api/userapi/v2';

const DATATHEOREM_USER_DOMAINS = [
  'datatheorem.com',
  'sourcetheorem.com',
  'biztheorem.com',
  'datatheorem.io',
];

export const isDataTheoremUser = (currentUser: User) => {
  return currentUser.login_email.match(
    `@(${DATATHEOREM_USER_DOMAINS.join('|')})$`,
  );
};

type UserAccountResponse = {
  current_user: User,
  account_info: AccountInfo,
};

export const REJECT_REASON = {
  NO_SESSION_ID: 'NO_SESSION_ID',
  EXPIRED_SESSION_ID: 'EXPIRED_SESSION_ID',
  UNKNOWN: 'UNKNOWN',
};

// We need to sue our own fetch, because otherwise there's a circular dependency:
// This package depends on the authorizedFetch to get the userAccount, and
// authorizedFetch depends on this package to get the session token.
export const fetchUserAccount = async (
  sessionId: ?string,
): Promise<UserAccountResponse> => {
  const response = await fetch(
    `${config.sevenhellAPI}${sevenhellApiVersion}/user_account`,
    {
      headers: {
        Authorization:
          typeof sessionId === 'string' ? `Session ${sessionId}` : '',
      },
    },
  );
  if (response.status === 401) {
    throw { reason: REJECT_REASON.EXPIRED_SESSION_ID };
  }
  if (response.status < 200 || response.status >= 300) {
    throw { reason: REJECT_REASON.UNKNOWN };
  }

  const json = await response.json();

  if (
    !json.current_user ||
    typeof json.current_user !== 'object' ||
    !json.account_info ||
    typeof json.account_info !== 'object'
  ) {
    throw new Error(`Invalid user_account response`);
  }

  return json;
};

export type UserAccount = {
  // The session id for use in sevenhell. If this is null, it's because either
  // `getSession` hasn't been called yet, or is still running! Subscribe to
  // state updates if you need to know when it's done.
  sessionId: string,

  // The current user profile. See above for why it's null.
  currentUser: User,

  // The current account profile. See above for why it's null.
  accountInfo: AccountInfo,

  // Convenience computed property added for discoverability despite the redundancy.
  canDownloadReports: boolean,
};

type UserAccountResult =
  | UserAccount
  | { no_session_reason: $Keys<typeof REJECT_REASON> };

// let stateId: number = Math.random();
let userAccountPromise: null | Promise<UserAccountResult> = null;
let userAccount: null | UserAccount = null;

// The purpose of this function is to start the process of figuring out if a
// session exists or not as early as possible. So preferably call it when
// initializing the application.
export const initialize: () => Promise<UserAccountResult> = () => {
  if (userAccountPromise) {
    return userAccountPromise;
  }

  userAccountPromise = new Promise((resolve, reject) => {
    const resolveIfAccountExists = () => {
      if (userAccount) {
        resolve(userAccount);
        return true;
      } else {
        return false;
      }
    };

    if (resolveIfAccountExists()) {
      return;
    }

    getGateSession(config.authHost)
      .then(sessionId => {
        if (resolveIfAccountExists()) {
          return;
        }

        if (!sessionId) {
          resolve({ no_session_reason: REJECT_REASON.NO_SESSION_ID });
          return;
        }

        fetchUserAccount(sessionId)
          .then(response => {
            if (resolveIfAccountExists()) {
              return;
            }

            // TODO: Replace with setUserAccount
            userAccount = {
              sessionId: sessionId,
              currentUser: response.current_user,
              accountInfo: response.account_info,

              // $FlowFixMe: suppress unsafe-getters-setters since this is an uncommon desired pattern.
              get canDownloadReports() {
                return this.accountInfo.account_status !== 'DEMO';
              },
            };
            resolve(userAccount);
          })
          .catch(err => {
            if (err && err.reason) {
              resolve({ no_session_reason: err.reason });
            } else {
              reject(err);
            }
          });
      })
      .catch(err => reject(err));
  });

  return userAccountPromise;
};

// Call this function if you need an imperative way to ensure you get the
// userAccount and don't care if an exception is thrown if the userAccount
// is not available (either because we haven't finished fetching it yet or
// because there's no valid session).
export function getInvariantUserAccount() {
  if (!userAccount) {
    throw new Error('userAccount not available yet');
  }
  return userAccount;
}

export function getUserAccount(): Promise<UserAccountResult> {
  if (userAccount !== null) {
    return Promise.resolve(userAccount);
  }
  return initialize();
}

// You can use this to set the userAccount manually if you already have it for
// some reason (such as tests or you are hydrating an existing session or
// something).
export function setUserAccount(newUserAccount: UserAccount) {
  userAccount = newUserAccount;
}

// Call this to reset this module to its starting state. Probably useful only
// for tests?
export function clearUserAccount() {
  userAccount = null;
  userAccountPromise = null;
  // stateId = Math.random();
}

// This function should only be used in tests in combination with
// getUserAccount:
// import { getUserAccount, fakeUserAccount } from '@dt/session';
// test('my test', async ()=>{
//   await getUserAccount(fakeUserAccount());
// })
export const fakeUserAccount = (
  mixin: $Shape<UserAccount> = { currentUser: {}, accountInfo: {} },
) => {
  // To help with finding errors in tests faster, we error when you provide
  // an invalid key
  if (
    !Object.keys(mixin).every(key =>
      [
        'currentUser',
        'accountInfo',
        'sessionId',
        'canDownloadReports',
      ].includes(key),
    )
  ) {
    throw new Error(
      `The mixin passed to fakeUserAccount must be in the shape { currentUser: {...}, accountInfo: {...}, sessionId: ..., canDownloadReports: ... }.
      You only need to provide the data you actually want to override, but your object had a top-level key that didn't match.
      Maybe you tried to pass something like { role: 'foo' } where it should be { currentUser: { role: 'foo' }}?`,
    );
  }
  return {
    currentUser: {
      allowed_app_ids: [],
      auth_strategy: 'GOOGLE_ACCOUNT',
      can_download_reports: false,
      date_created: '2017-12-12T20:40:13.758030',
      date_updated: '2017-12-12T20:44:28.342530',
      first_name: 'A Data Theorem',
      id: '123',
      last_name: 'Admin',
      login_email: 'test@sourcetheorem.com',
      role: 'MANAGER',
      can_access_app_secure: true,
      can_access_app_search: true,
      can_access_brand_protect: true,
      can_access_api_inspect: true,
      can_invite_users: true,
      ...mixin.currentUser,
    },
    accountInfo: {
      name: 'Test Customer',
      toggles: {
        mediawatch: false,
        openscan: false,
        scan_and_secure: true,
      },
      clonewatch_subscription: 'NO_SUBSCRIPTION',
      openscan_subscription: 'NO_SUBSCRIPTION',
      ...mixin.accountInfo,
    },
    sessionId:
      typeof mixin.sessionId !== 'undefined'
        ? mixin.sessionId
        : 'test-sessionid',
    canDownloadReports: Boolean(mixin.canDownloadReports),
  };
};

// This function should only be used to cleanup the result of
// fakeUserAccount after tests.
export const __clearMockUserAccount = async () => {
  clearUserAccount();
};
