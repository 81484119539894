// @flow
import '@dt/global';
import React from 'react';
import { render } from 'react-dom';
import { ErrorBoundary } from '@dt/components';
import { initialize } from '@dt/session';
import type { ResolverMap } from 'graphql-tools';
import Application from './Application';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './sagas';

// istanbul ignore next because it bootstraps the application
export default function runApp(
  renderFn: typeof render = render,
  graphQLMocks: void | ResolverMap,
) {
  const domContainer = document.getElementById('react');

  if (!domContainer) {
    alert(`Couldn't start the application. Clear cache and refresh.`);
    console.error(`There is no DOM element with id=react!`);
    return;
  }

  initialize();

  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);

  global.__DT_STORE = store;

  runSagas(reduxSaga);

  return renderFn(
    <ErrorBoundary>
      <Application store={store} graphQLMocks={graphQLMocks} />
    </ErrorBoundary>,
    domContainer,
  );
}
