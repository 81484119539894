//@flow
import React from 'react';
import Box from '@material-ui/core/Box';
import Button from './../Button';

/*
 * Allows the user to go to the previous or next step in the stepper.
 *
 * @param prevOnClick - previous onClick event
 * @param nextOnClick - next onClick event
 * @param prevLabel - previous label
 * @param nextLabel - next label
 * @param prevDisabled - previous button disabled
 * @param nextDisabled - next button disabled
 * @param prevExclude - exclude the previous button
 * @param nextExclude - exclude the next button
 */
const ConfigurationsCloudStepperNextPreviousStepActionsComponent = function ConfigurationsCloudStepperNextPreviousStepActions({
  prevOnClick,
  nextOnClick,
  prevLabel = 'Previous',
  nextLabel = 'Next',
  prevDisabled = false,
  nextDisabled = false,
  prevExclude = false,
  nextExclude = false,
  prevIsLoading = false,
  nextIsLoading = false,
}: {|
  prevOnClick?: () => void,
  nextOnClick?: () => void,
  nextLabel?: string,
  prevLabel?: string,
  nextDisabled?: boolean,
  prevDisabled?: boolean,
  prevExclude?: boolean,
  nextExclude?: boolean,
  prevIsLoading?: boolean,
  nextIsLoading?: boolean,
|}) {
  return (
    <div style={{ margin: '12px 0px' }}>
      <Box display="flex">
        {!prevExclude && (
          <div style={{ marginRight: 12 }}>
            <Button
              variant="text"
              onClick={prevOnClick}
              isLoading={prevIsLoading}
              disabled={prevDisabled}
              aria-label={prevLabel}
            >
              {prevLabel}
            </Button>
          </div>
        )}

        {!nextExclude && (
          <div>
            <Button
              variant="contained"
              color="primary"
              aria-label={nextLabel}
              isLoading={nextIsLoading}
              disabled={nextDisabled}
              onClick={nextOnClick}
            >
              {nextLabel}
            </Button>
          </div>
        )}
      </Box>
    </div>
  );
};

export const ConfigurationsCloudStepperNextPreviousStepActions = ConfigurationsCloudStepperNextPreviousStepActionsComponent;
