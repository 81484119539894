//@flow
import gql from 'graphql-tag';

const create = gql`
  mutation SupportRequestsCreateMutation(
    $body: HorizonSupportRequestsCreateParams!
  ) {
    horizon_support_requests_create(body: $body) {
      nothing
    }
  }
`;

export default {
  create,
};
